<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: 0.4px;
              color: #000000"
              >
                {{ $t("Part") }} 2: {{ $t("Competency Assessment") }} ({{ $t("divided into two") }} (2) {{ $t("competencies") }}).
              </span>
            </b-card-header>
            <hr>
            <b-tabs
              nav-class="saj-title m-0"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <!-- kompetensi teras -->
              <b-tab
                :title="$t('2a. Core Competencies')"
                active
              >
                <div class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end">
                  {{ total_score1 }}/{{ core_weightage }} %
                </div>
                <div
                  class="d-flex justify-content-end"
                >
                  <div
                    v-if="status.toLowerCase() !== 'completed'"
                    class="d-flex justify-content-end"
                  >
                    <b-col
                      style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                    >
                      <b-button
                        :style="approved_at === null ? '' : 'display: none;'"
                        variant="primary"
                        class="saj-title"
                        style="margin-right: 10px"
                        @click="clickEditBtn()"
                      >
                        {{ disableRating ? $t("Edit") : $t("Cancel") }} </b-button>
                      <b-button
                        :style="approved_at === null ? '' : 'display: none;'"
                        class="saj-title"
                        style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                        :disabled="disableRating || getSimilarities()"
                        @click="updateCore()"
                      >
                        {{ $t("Save") }}
                      </b-button>
                    </b-col>
                  </div>
                </div>
                <div
                  class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                  style="font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px;
                          letter-spacing: 0.4px;
                          color: #000000;"
                >
                  {{ $t("Please rate all three competencies and the key items for each of those competencies") }}.
                  {{ $t("This competency is the foundation") }}/{{ $t("value of the company that must be possessed by all members in all positions") }}.
                </div>
                <validation-observer ref="validateCore">
                  <!-- Teras 1 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row
                        class="text-center"
                      >
                        <b-col
                          cols="12"
                          md="8"
                          class="saj-title d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-weight: 400;
                            line-height: 16px;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >
                          {{ $t("CORE") }} 1:
                          <span
                            class="saj-title"
                            style="
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 115px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? corename1.name_bi : corename1.name }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ isEnglish ? name0.name_bi : name0.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text pl-2"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ isEnglish ? description0.description_bi : description0.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          p-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >

                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name0.name_bi : name0.name"
                          :rules="'required'"
                        >
                          <b-form-rating
                            v-model="selfScore.core1._1"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5> {{ getRatingText(selfScore.core1._1) }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager1 }} /6

                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ isEnglish ? name1.name_bi : name1.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ isEnglish ? description1.description_bi : description1.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="self-score d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name1.name_bi : name1.name"
                          :rules="'required'"
                        >
                          <b-form-rating
                            v-model="selfScore.core1._2"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                           <h5> {{ getRatingText(selfScore.core1._2) }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager2 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Teras 2 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="8"
                          class="saj-title d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("CORE") }} 2:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? corename2.name_bi : corename2.name }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ isEnglish ? name2.name_bi : name2.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ isEnglish ? description2.description_bi : description2.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name2.name_bi : name2.name"
                          :rules="'required'"
                        >
                          <b-form-rating
                            v-model="selfScore.core2._1"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                           <h5> {{getRatingText(selfScore.core2._1) }} </h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager3 }} /6

                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              <!-- background:lightgreen; -->
                            "
                          >{{ isEnglish ? name3.name_bi : name3.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                              <!-- background:lightblue; -->
                            "
                          >{{ isEnglish ? description3.description_bi : description3.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="self-score d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name3.name_bi : name3.name"
                          :rules="'required'"
                        >
                          <b-form-rating
                            v-model="selfScore.core2._2"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                         {{ getRatingText(selfScore.core2._2)}}
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager4 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Teras 3 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="8"
                          class="saj-title d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("CORE") }} 3:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? corename3.name_bi : corename3.name }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ isEnglish ? name4.name_bi : name4.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ isEnglish ? description4.description_bi : description4.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name4.name_bi : name4.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="selfScore.core3._1"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                         <h5> {{getRatingText(selfScore.core3._1) }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager5 }} /6

                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class="saj-text"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ isEnglish ? name5.name_bi : name5.name }}</b-col>
                          <b-col
                            cols="10"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ isEnglish ? description5.description_bi : description5.description }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="self-score d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name5.name_bi : name5.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="selfScore.core3._2"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5> {{getRatingText(selfScore.core3._2) }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager6 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                </validation-observer>
              </b-tab>
              <!-- kompetensi tingkahlaku -->
              <b-tab :title="$t('2b. Behavioural Competencies')">
                <div class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end">
                  {{ total_score2 }}/{{ behaviour_weightage }} %
                </div>
                <div
                  class="d-flex justify-content-end"
                >
                  <div
                    v-if="status.toLowerCase() !== 'completed'"
                    class="d-flex justify-content-end"
                  >
                    <b-col
                      style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                    ><b-button
                       :style="approved_at_beh === null ? '' : 'display: none;'"
                       variant="primary"
                       class="saj-title"
                       style="margin-right: 10px"
                       @click="toggleInput2(), disableRating2 === true ? getBehaviour() : null"
                     >
                       {{ $t("Edit") }} </b-button>
                      <!-- <div v-if="approved_at_beh === null"> -->
                      <b-button
                        :style="approved_at_beh === null ? '' : 'display: none;'"
                        class="saj-title"
                        style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                        :disabled="disableRating2"
                        @click="updateBehaviour()"
                      >
                        {{ $t("Save") }}
                      </b-button>
                      <!-- </div> -->
                      <!-- <div v-else>
                        <b-button
                          class="saj-title m-1"
                          success
                          style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
                        >
                          {{ $t("Your performance have been approved") }}
                        </b-button>
                      </div> -->
                    </b-col>
                  </div>
                </div>
                <div
                  class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                  style="font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;"
                >
                  {{ $t("Please value this Behavioral Competency as stated in the Job Competency Matrix") }}.
                  {{ $t("It is a trait") }}/{{ $t("characteristic needed to produce better work performance") }}.
                </div>
                <validation-observer ref="validateBehaviour">
                  <!-- Tingkahlaku 1 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          class="saj-title"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("BEHAVIOURAL") }} 1:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          > {{ isEnglish ? name6.name_bi : name6.name }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description,index) in description6"
                            :key="index"
                            cols="12"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name6.name_bi : name6.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="value7"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value7 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="value7 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="value7 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="value7 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="value7 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="value7 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager7 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 2 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          class="saj-title"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("BEHAVIOURAL") }} 2:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          > {{ isEnglish ? name7.name_bi : name7.name }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description,index) in description7"
                            :key="index"
                            cols="12"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name7.name_bi : name7.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="value8"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value8 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="value8 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="value8 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="value8 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="value8 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="value8 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager8 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 3 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          class="saj-title"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("BEHAVIOURAL") }} 3:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? name8.name_bi : name8.name }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description,index) in description8"
                            :key="index"
                            cols="12"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name8.name_bi : name8.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="value9"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value9 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="value9 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="value9 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="value9 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="value9 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="value9 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager9 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 4 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          class="saj-title"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("BEHAVIOURAL") }} 4:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? name9.name_bi : name9.name }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description,index) in description9"
                            :key="index"
                            cols="12"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >

                            <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name9.name_bi : name9.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="value10"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value10 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="value10 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="value10 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="value10 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="value10 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="value10 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager10 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 5 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          class="saj-title"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >{{ $t("BEHAVIOURAL") }} 5:
                          <span
                            class="saj-title"
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ isEnglish ? name10.name_bi : name10.name }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description,index) in description10"
                            :key="index"
                            cols="12"
                            class="saj-text"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >

                            <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="isEnglish ? name10.name_bi : name10.name"
                          rules="required"
                        >
                          <b-form-rating
                            v-model="value11"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value11 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="value11 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="value11 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="value11 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="value11 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="value11 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-row>
                      <b-row
                        class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                          p-0
                          m-0
                          "
                        style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                      >

                        {{ $t("Manager Score") }}: {{ manager11 }} /6

                      </b-row>
                    </b-card>
                  </b-col>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BContainer,
  // BImg,
  BButton,
  BTabs,
  BTab,
  BFormRating,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import { mapGetters } from 'vuex'
import SAJToast from "@/component/saj-toastification.vue"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import _ from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BTabs,
    BTab,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      
      id: this.$route.params.id,
      status: this.$route.params.status,
      performance_id: this.$route.params.performance_id,
      required,
      idCore: "",
      jdKra: "",
      approved_at: null,
      approved_at_beh: null,
      name0: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      name6: "",
      name7: "",
      name8: "",
      name9: "",
      name10: "",
      core1: "",
      core2: "",
      core3: "",
      subcore1_1: "",
      subcore1_2: "",
      subcore2_1: "",
      subcore2_2: "",
      subcore3_1: "",
      subcore3_2: "",
      corename1: "",
      corename2: "",
      corename3: "",
      description0: "",
      description1: "",
      description2: "",
      description3: "",
      description4: "",
      description5: "",
      description6: [],
      description7: [],
      description8: [],
      description9: [],
      description10: [],
      /// ////////////// core //////////////////////
      selfScore: {
        core1: {
          _1: '',
          _2: ''
        },
        core2: {
          _1: '',
          _2: ''
        },
        core3: {
          _1: '',
          _2: ''
        },
      },
      originalCore: {
        core1: {
          _1: '',
          _2: ''
        },
        core2: {
          _1: '',
          _2: ''
        },
        core3: {
          _1: '',
          _2: ''
        },
      },
      value4: "",
      value5: "",
      value6: "",
      /// ////////////// core - manager score //////////////////////
      manager1: "",
      manager2: "",
      manager3: "",
      manager4: "",
      manager5: "",
      manager6: "",
      /// //////////////// behaviour //////////////////////
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      /// ////////////// behavior - manager score //////////////////////
      manager7: "",
      manager8: "",
      manager9: "",
      manager10: "",
      manager11: "",

      /// /////////////////////end data//////////////////////////
      disableRating: true,
      disableRating2: true,
      total_score1: null,
      total_score2: null,
      allCoreData: {},
      rating: [],
      core_weightage: null,
      behaviour_weightage: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    refresh: {
      handler(val) {
        if (val) {
          this.getCore()
          this.getBehaviour()
        }
        // console.log("refresh part 2", val)
      }
      ,
    },
  },
  mounted(){
    this.getScoreRating()
    this.getCore()
    this.getBehaviour()
    this.weightage()
  },
  methods: {
    getSimilarities() {
       return _.isEqual(this.originalCore, this.selfScore)
    },
    getRatingText(val) {
      let labelText = ''
      switch (val) {
        case 1:
          labelText = this.isEnglish ? this.rating[5].definition_en : this.rating[5].definition_my
          break;
           case 2:
          labelText = this.isEnglish ? this.rating[4].definition_en : this.rating[4].definition_my
          break;
           case 3:
          labelText = this.isEnglish ? this.rating[3].definition_en : this.rating[3].definition_my
          break;
           case 4:
          labelText = this.isEnglish ? this.rating[2].definition_en : this.rating[2].definition_my
          break;
           case 5:
          labelText = this.isEnglish ? this.rating[1].definition_en : this.rating[1].definition_my
          break;
           case 6:
          labelText = this.isEnglish ? this.rating[0].definition_en : this.rating[0].definition_my
          break;
          default:
            labelText = ''
      }
      return labelText

    },
    clickEditBtn() {
       this.disableRating = !this.disableRating
    },
    toggleInput2() {
      this.disableRating2 = !this.disableRating2
    },

    getEmpPerformance(){
      const param = new FormData()
      // data.append("user_id", this.idCore)
      this.$axios.get(`${this.$baseUrl}/getEmployeePerformanceSet?user_id=${this.idCore}`, param).then(response => {
        this.jdKra = response.data.data.jd_kra
      })
    },
    getCore() {
      const params = new FormData()
      params.append("user_id", this.id)
      params.append("cycle_id", this.performance_id)

      this.$axios.get(`${this.$baseUrl}/myperformances/competency_core`, params).then(response => {
        // console.log(response)
        // console.log("sini cycle id", this.id)
        this.approved_at = response.data.data.submit_at
        if (response.data.success === true) {

          this.allCoreData = response.data.data
          this.idCore = response.data.data.user_id
          const jobData0 = response.data.data.active_competency_core_performance[0]
          const jobData1 = response.data.data.active_competency_core_performance[1]
          const jobData2 = response.data.data.active_competency_core_performance[2]
          const jobData3 = response.data.data.active_competency_core_performance[3]
          const jobData4 = response.data.data.active_competency_core_performance[4]
          const jobData5 = response.data.data.active_competency_core_performance[5]

          this.name0 = jobData0.subcore
          this.name1 = jobData1.subcore
          this.name2 = jobData2.subcore
          this.name3 = jobData3.subcore
          this.name4 = jobData4.subcore
          this.name5 = jobData5.subcore

          this.selfScore = {
            core1: {
              _1: jobData0.self_score === 0 ? "" : jobData0.self_score,
              _2: jobData1.self_score === 0 ? "" : jobData1.self_score
            },
             core2: {
              _1: jobData2.self_score === 0 ? "" : jobData2.self_score,
              _2: jobData3.self_score === 0 ? "" : jobData3.self_score
            },
             core3: {
              _1: jobData4.self_score === 0 ? "" : jobData4.self_score,
              _2: jobData5.self_score === 0 ? "" : jobData5.self_score
            },
          }
          this.originalCore = _.cloneDeep(this.selfScore)

          this.manager1 = jobData0.manager_score
          this.manager2 = jobData1.manager_score
          this.manager3 = jobData2.manager_score
          this.manager4 = jobData3.manager_score
          this.manager5 = jobData4.manager_score
          this.manager6 = jobData5.manager_score

          this.description0 = jobData0.subcore
          this.description1 = jobData1.subcore
          this.description2 = jobData2.subcore
          this.description3 = jobData3.subcore
          this.description4 = jobData4.subcore
          this.description5 = jobData5.subcore

          this.core1 = jobData0.core_id
          this.core2 = jobData2.core_id
          this.core3 = jobData4.core_id
          this.subcore1_1 = jobData0.subcore_id
          this.subcore1_2 = jobData1.subcore_id
          this.subcore2_1 = jobData2.subcore_id
          this.subcore2_2 = jobData3.subcore_id
          this.subcore3_1 = jobData4.subcore_id
          this.subcore3_2 = jobData5.subcore_id

          this.corename1 = jobData0.core
          this.corename2 = jobData2.core
          this.corename3 = jobData4.core

          this.total_score1 = response.data.data.total_score
        } else {
          // this.$errorConfirmation()
          this.errorConfirmation('danger')
        }
        this.getEmpPerformance()
      })
    },
    getBehaviour() {
      const params = new FormData()

      params.append("user_id", this.id)
      params.append("cycle_id", this.performance_id)

      this.$axios.get(`${this.$baseUrl}/myperformances/competency_behaviour`, params).then(response => {
        this.approved_at_beh = response.data.data.submit_at
        if (response.data.success === true){
          const jobData0 = response.data.data.active_competency_behaviour_performance[0]
          const jobData1 = response.data.data.active_competency_behaviour_performance[1]
          const jobData2 = response.data.data.active_competency_behaviour_performance[2]
          const jobData3 = response.data.data.active_competency_behaviour_performance[3]
          const jobData4 = response.data.data.active_competency_behaviour_performance[4]
          this.name6 = jobData0.competency_behaviour
          this.name7 = jobData1.competency_behaviour
          this.name8 = jobData2.competency_behaviour
          this.name9 = jobData3.competency_behaviour
          this.name10 = jobData4.competency_behaviour

          this.value7 = jobData0.self_score === 0 ? "" : jobData0.self_score
          this.value8 = jobData1.self_score === 0 ? "" : jobData1.self_score
          this.value9 = jobData2.self_score === 0 ? "" : jobData2.self_score
          this.value10 = jobData3.self_score === 0 ? "" : jobData3.self_score
          this.value11 = jobData4.self_score === 0 ? "" : jobData4.self_score

          this.manager7 = jobData0.manager_score
          this.manager8 = jobData1.manager_score
          this.manager9 = jobData2.manager_score
          this.manager10 = jobData3.manager_score
          this.manager11 = jobData4.manager_score

          for (let i = 0; i < jobData0.competency_behaviour.subbehaviour.length; i += 1){
            this.description6[i] = jobData0.competency_behaviour.subbehaviour[i]
            // console.log(this.description6[i])
          }
          for (let i = 0; i < jobData1.competency_behaviour.subbehaviour.length; i += 1){
            this.description7[i] = jobData1.competency_behaviour.subbehaviour[i]
          }
          for (let i = 0; i < jobData2.competency_behaviour.subbehaviour.length; i += 1){
            this.description8[i] = jobData2.competency_behaviour.subbehaviour[i]
          }
          for (let i = 0; i < jobData3.competency_behaviour.subbehaviour.length; i += 1){
            this.description9[i] = jobData3.competency_behaviour.subbehaviour[i]
          }
          for (let i = 0; i < jobData4.competency_behaviour.subbehaviour.length; i += 1){
            this.description10[i] = jobData4.competency_behaviour.subbehaviour[i]
          }

          this.behaviour_id1 = jobData0.behaviour_id
          this.behaviour_id2 = jobData1.behaviour_id
          this.behaviour_id3 = jobData2.behaviour_id
          this.behaviour_id4 = jobData3.behaviour_id
          this.behaviour_id5 = jobData4.behaviour_id

          this.total_score2 = response.data.data.total_score
        } else {
          // this.$errorConfirmation()
          this.errorConfirmation('danger')
        }
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.userDetails.business_unit)
      params.append('role_id', this.userDetails.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.core_weightage = response.data.data.core_competency
        this.behaviour_weightage = response.data.data.behavioural_competency
      })
    },
    updateCore() {
      this.$refs.validateCore.validate().then(success => {
        if (success) {
          this.clickEditBtn()
          const data = new FormData()
          data.append(`self_score[${this.core1}][${this.subcore1_1}]`, this.selfScore.core1._1)
          data.append(`self_score[${this.core1}][${this.subcore1_2}]`, this.selfScore.core1._2)
          data.append(`self_score[${this.core2}][${this.subcore2_1}]`, this.selfScore.core2._1)
          data.append(`self_score[${this.core2}][${this.subcore2_2}]`, this.selfScore.core2._2)
          data.append(`self_score[${this.core3}][${this.subcore3_1}]`, this.selfScore.core3._1)
          data.append(`self_score[${this.core3}][${this.subcore3_2}]`, this.selfScore.core3._2)

          this.$axios.post(`${this.$baseUrl}/myperformances/competency_core`, data).then(() => {

            this.getCore()
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully saved')}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          })
        }
      })
    },
    updateBehaviour() {
      this.$refs.validateBehaviour.validate().then(success => {
        if (success) {
          this.toggleInput2()
          const data = new FormData()
          data.append(`self_score[${this.behaviour_id1}]`, this.value7)
          data.append(`self_score[${this.behaviour_id2}]`, this.value8)
          data.append(`self_score[${this.behaviour_id3}]`, this.value9)
          data.append(`self_score[${this.behaviour_id4}]`, this.value10)
          data.append(`self_score[${this.behaviour_id5}]`, this.value11)

          // data.append("user_id", this.id)
          // const jobData0 = response.data.data.active_competency_core_performance[0]
          // data.append('self_score', this.self_score[jobData0.default_subcore.core_id])

          // eslint-disable-next-line no-unused-vars
          this.$axios.post(`${this.$baseUrl}/myperformances/competency_behaviour`, data).then(response => {
            // if (response.data.success === true){
            //   data.append('', this.profile.full_name)
            // } else {
            //   // this.$errorConfirmation()
            //   this.errorConfirmation('danger')
            // }
            this.getBehaviour()
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully saved')}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          })
        }
      })
    },
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
  },
}
</script>
<style>
/* .bahagian{
  font-size:22px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
} */
.bahagian2{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: end !important
}
/* .tab-title{
  font-size: 22px;
} */
.kandungan{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: start;
}
/* .core{
  font-size: 20px;
  font-family: 'Poppins';
  font-style: normal;
  letter-spacing: 0.4px;
  color: #000000;
} */
</style>
