<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  color: #000000;
                "
              >
                {{ $t("Part") }} 4: {{ $t("Overall Performance Comments") }} -
                {{ $t("Please complete at the end of the Performance Appraisal") }}.
              </span>
            </b-card-header>

            <hr>
            <div
              nav-class="mb-3"
              style=""
            >
              <div title="">
                <div
                  v-if="status.toLowerCase() !== 'completed'"
                  class="d-flex justify-content-end"
                >
                  <b-col
                    style="
                      display: flex;
                      justify-content: flex-end;
                    "
                  ><b-button
                     :style="approved_at === null ? '' : 'display: none;'"
                     variant="primary"
                     class="saj-button mr-1"
                     @click="toggleInput"
                   >
                     {{ $t("Edit") }}
                   </b-button>
                    <b-button
                      v-if="!isSave"
                      :style="approved_at === null ? '' : 'display: none;'"
                      class="saj-button mr-1"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                      :disabled="disableInput"
                      @click="updateComment(), isSave = true"
                    >
                      {{ $t("Save") }}
                    </b-button>
                    <b-button
                      v-if="isSave"
                      class="saj-button mr-1"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                    >
                      <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
                    </b-button>
                    <!-- <div v-else>
                      <b-button
                        class="saj-button m-1"
                        success
                        style="
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
                      >
                        {{ $t("Your performance have been approved") }}
                      </b-button>
                    </div> -->
                    <div v-if="approved_at === null">
                      <b-button
                        variant="primary"
                        class="saj-button"
                        style="margin-right: 10px"
                        :disabled="!disableInput"
                        @click="checkComment()"
                      >
                        {{ $t("Submit") }}
                      </b-button>
                    </div>
                    <div v-else-if="approved_sv_at !== null">
                      <b-button
                        v-b-modal.agree-performance
                        :style="agree_at === null ? '' : 'display: none;'"
                        class="saj-button m-1"
                        variant="primary"
                      >
                        {{ $t("Agree") }}
                      </b-button>
                    </div>
                  </b-col>
                </div>
                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISEE") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                  >
                    <!-- card 1 inside-->
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <validation-observer ref="simpleRules">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Comment')"
                          rules="required"
                        >
                          <textarea
                            v-model="comment"
                            class="saj-text form-control flex-grow-1"
                            name="textarea"
                            :disabled="disableInput"
                            style="height: 150px
                                  border: 1px solid #d8d6de;
                                  border-radius: 0.750rem;
                                  "
                            rows="5"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </validation-observer>
                    </div>
                    <div
                      class="saj-text"
                      style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          letter-spacing: 0.15px;
                        "
                    >
                      <!-- <div>Nama Penyelia : {{ nama }}</div>
                        <div>Jawatan : {{ jawatan }}</div> -->
                    </div>
                  </b-card>
                </b-col>
                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="color: #000000;"
                  >
                    {{ $t("COMMENTS BY APPRAISER") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <textarea
                      v-model="comment2"
                      class="saj-text form-control flex-grow-1"
                      name="textarea"
                      disabled
                      style="height: 150px
                            border: 1px solid #d8d6de;
                            border-radius: 0.750rem;
                            background: #ffffff;
                            "
                      rows="5"
                    />
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div> {{ $t("Appraiser Name") }}: {{ svname }}</div>
                          <div> {{ $t("Position") }}: {{ $t(svposition) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>
                            {{ $t("Previous Comments") }}:
                            <feather-icon
                              v-if="previous_comment === 1"
                              v-b-tooltip.hover.bottom="'Previous'"
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                              @click="getAppraiserComment('previous')"
                            />
                            <feather-icon
                              v-else
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                            />

                            {{ $t(komen) }}

                            <feather-icon
                              v-if="next_comment === 1"
                              v-b-tooltip.hover.bottom="'Next'"
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                              @click="getAppraiserComment('next')"
                            />
                            <feather-icon
                              v-else
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                            />
                          </div>
                          <div>{{ $t("Date") }}: {{ $t(DateChanged(tarikh2)) }}</div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISER'S SUPERVISOR") }} / {{ $t("HEAD OF DEPARTMENT") }} /
                    {{ $t("HEAD OF DIVISION") }} / {{ $t("CEO") }} /
                    {{ $t("COO") }} / {{ $t("CHAIRMAN") }}
                  </span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <textarea
                      v-model="comment3"
                      class="saj-text form-control flex-grow-1"
                      name="textarea"
                      disabled
                      style="height: 150px
                            border: 1px solid #d8d6de;
                            border-radius: 0.750rem;
                            background: #ffffff;
                            "
                      rows="5"
                    />
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Appraiser's Supervisor Name") }}: {{ hod_data === null ? appraiser_hr_name : appraiser_hod_name }}</div>
                          <div> {{ $t("Designation") }}: {{ $t(hod_data === null ? appraiser_hr_position : appraiser_hod_position) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div> {{ $t("Date") }}: {{ $t(DateChanged(tarikh3)) }}</div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <div class="d-flex justify-content-end m-0 mr-1">
                  <b-button
                    class="saj-button"
                    style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                    :style="(comment2 === 'No Comment' && comment3 === 'No Comment') ? `display: none;` : ``"
                    @click="multipleComments()"
                  >

                    {{ $t("Download") }}
                  </b-button>
                </div>
                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("PROPOSED INDIVIDUAL TRAINING AND DEVELOPMENT NEEDS") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-card
                      type="text"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-col
                        style="height: 100% "
                      >
                        <!-- <b-col
                            v-for="suggest in existSuggests"
                            :id="suggest.id"
                            :key="suggest.id"
                            cols="12"
                            style="font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: ##a9a9a9;"
                          >

                            <ul><li> {{ suggest.suggestion }}</li></ul>

                          </b-col> -->
                        <div
                          v-for="(i, idx) in suggestion "
                          :key="idx"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          {{ i }}
                        </div>
                      </b-col>
                    </b-card>
                    <b-row>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}: {{ svname }}</div>
                          <div>{{ $t("Position") }}: {{ $t(svposition) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </div>
            </div>
            <b-modal
              id="submit-performance"
              :hide-footer="true"
              :hide-header="true"
              :centered="true"
            >
              <submit-perf
                :title="$t('Are you sure to submit your performance?')"
                @cancel="$bvModal.hide('submit-performance')"
                @confirm="submitPerformance()"
              />
            </b-modal>
            <b-modal
              id="agree-performance"
              :hide-footer="true"
              :hide-header="true"
              :centered="true"
            >
              <submit-perf
                confirm-btn="Yes"
                cancel-btn="No"
                title="Are you sure you agree ?"
                @cancel="$bvModal.hide('agree-performance')"
                @confirm="agreePerformance()"
              />
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  // BContainer,
  // BImg,
  BButton,
  BCardHeader,
  //   BTabs,
  //   BTab,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import jsPDF from 'jspdf'
// import SAJToast from '@/component/saj-toastification.vue'
import SAJToast from "@/component/saj-toastification.vue"
import submitPerf from "@/pages/alert-modal.vue"
import moment from "moment"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    submitPerf,
    BRow,
    BCol,
    BCard,
    // BContainer,
    // BImg,
    BButton,
    BCardHeader,
    // BTabs,
    // BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: this.$route.params.id,
      status: this.$route.params.status,
      approved_at: null,
      approved_sv_at: null,
      agree_at: null,
      nama: " ",
      komen: "",
      svposition: "",
      tarikh: "",
      tarikh2: "",
      tarikh3: "",
      jawatan: " ",
      description: " ",
      suggestion: "",
      comment: "",
      comment2: "",
      comment3: "",
      comment4: "",
      svname: "",
      position: "",
      hod_data: null,
      appraiser_hod_name: "",
      appraiser_hod_position: "",
      appraiser_hr_name: "",
      appraiser_hr_position: "",
      disableInput: true,
      existSuggests: [],
      performanceID: null,
      appraiser_comment: null,
      next_comment: 0,
      previous_comment: 0,
      allComments: [],
      comments: [],
      required,
      isSave: false,
    }
  },
  mounted() {
    this.getData()
    this.getSuggestion()
    // this.submitPerformance()
    // this.getData2()
    // this.getSVinfo()
  },
  methods: {
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    getImage(url){
      return new Promise(resolve => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous') // getting images from external domain

        // eslint-disable-next-line func-names
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight

          // next three lines for white background in case png has a transparent background
          const ctx = canvas.getContext('2d')
          ctx.fillStyle = '#fff' /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height)

          canvas.getContext('2d').drawImage(this, 0, 0)

          resolve(canvas.toDataURL('image/jpeg'))
        }

        image.src = url
      })
    },
    async showComments(){
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()
      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()
      const logo = await this.getImage("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Ranhill_Utilities_Berhad_Logo_%28Original%29.png/922px-Ranhill_Utilities_Berhad_Logo_%28Original%29.png?20211125033701")
      // console.log("Image:", logo)
      for (let i = 0; i < this.comments.length; i += 1){
        doc.addImage(logo, 'png', 20, 15, 18, 26, 'a', 'FAST')
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
        doc.setFontSize(12)
        doc.setTextColor(0, 0, 0)
        doc.text(20, 55, `COMMENTS BY APPRAISER`)
        doc.text(20, 170, `COMMENTS BY APPRAISER'S SUPERVISOR`)
        doc.rect(20, 60, width / 1.24, 80)
        doc.rect(20, 135 + 40, width / 1.24, 80)
        doc.setFontSize(10)
        try {
          const comment = this.comments[i].comment === null ? "No Comment" : this.comments[i].comment
          doc.text(25, 67, comment, { align: "left", maxWidth: pageWidth - 51 })
        } catch (e) {
          doc.text(25, 67, "No Comment")
        }
        try {
          const comment = this.allComments.appraiser_sv_comment.comment === null ? "No Comment" : this.allComments.appraiser_sv_comment.comment
          doc.text(25, 182, comment, { align: "left", maxWidth: pageWidth - 51 })
        } catch (e) {
          doc.text(25, 182, "No Comment")
        }
        doc.setFontSize(10)
        // doc.text(30, 145, `Appraiser Name: ${this.comments[i].appraiser_name}`)
        try {
          doc.text(20, 145, `Appraiser Name: ${this.comments[i].appraiser_name === null ? '-' : this.comments[i].appraiser_name}`)
        } catch {
          doc.text(20, 145, `Appraiser Name: -`)
        }
        // doc.text(30, 150, `Designation: ${this.comments[i].appraiser_position}`)
        try {
          doc.text(20, 150, `Designation: ${this.comments[i].appraiser_position === null ? '-' : this.comments[i].appraiser_position}`)
        } catch {
          doc.text(20, 150, `Designation: -`)
        }
        try {
          doc.text(20, 155, `Date: ${this.allComments.appraiser_sv_comment.updated_at.split("T")[0].split("-")[2]}-${this.allComments.appraiser_sv_comment.updated_at.split("T")[0].split("-")[1]}-${this.allComments.appraiser_sv_comment.updated_at.split("T")[0].split("-")[0]}`)
        } catch {
          doc.text(20, 155, `Date: -`)
        }
        // doc.text(120, 145, `Date: ${this.comments[i].date.split("T")[0]}`)
        //

        try {
          doc.text(20, 260, `Appraiser's Supervisor Name: ${this.allComments.appraiser_sv.full_name === null ? '-' : this.allComments.appraiser_sv.full_name}`)
        } catch {
          doc.text(20, 260, `Appraiser's Supervisor Name: -`)
        }
        try {
          doc.text(20, 265, `Designation: ${this.allComments.appraiser_sv.position === null ? '-' : this.allComments.appraiser_sv.position}`)
        } catch {
          doc.text(20, 265, `Designation: -`)
        }
        try {
          doc.text(20, 270, `Date: ${this.comments[i].date.split("T")[0].split("-")[2]}-${this.comments[i].date.split("T")[0].split("-")[1]}-${this.comments[i].date.split("T")[0].split("-")[0]}`)
        } catch {
          doc.text(20, 270, `Date: -`)
        }
        doc.addPage()
      }
      const pageCount = doc.internal.getNumberOfPages()
      doc.deletePage(pageCount)
      doc.save(`Report Ranhill.pdf`)
      // doc.output('dataurlnewwindow', 'newfile.pdf')
    },

    getData() {
      this.$axios.get(`${this.$baseUrl}/myperformances/comment?cycle_id=${this.id}`).then(response => {
        this.performanceID = response.data.data.id
        // console.log("comment appraiser sv", response)
        this.getAppraiserComment()
        this.approved_at = response.data.data.submit_at
        this.approved_sv_at = response.data.data.approved_sv_at
        this.agree_at = response.data.data.agree_at
        // console.log("sini submit at", response.data.data.approved_sv_at)
        this.allComments = response.data.data
        if (response.data.data.comment_performance !== null) {
          this.comment = response.data.data.comment_performance.comment !== null ? response.data.data.comment_performance.comment : ''
        } else {
          // Kalau takde langsung
          this.comment = ''
        }
        if (response.data.data.appraiser_sv_hod_comment !== null){
          this.comment3 = response.data.data.appraiser_sv_hod_comment.comment !== null ? response.data.data.appraiser_sv_hod_comment.comment : `${this.$t('No Comment')}`
          this.tarikh3 = response.data.data.appraiser_sv_hod_comment.updated_at.slice(0, 10) !== null ? response.data.data.appraiser_sv_hod_comment.updated_at.slice(0, 10) : '-'
        } else {
          this.comment3 = ''
          this.tarikh3 = '-'
        }
        this.svname = response.data.data.supervisor === null ? '-' : response.data.data.supervisor.full_name
        this.svposition = response.data.data.supervisor === null ? '-' : response.data.data.supervisor.position
        this.hod_data = response.data.data.appraiser_sv_hod

        if (this.hod_data !== null) {
          this.appraiser_hod_name = this.hod_data.full_name
          this.appraiser_hod_position = this.hod_data.position
        }

        if (response.data.data.appraiser_hod_hr !== undefined) {
          this.appraiser_hr_name = response.data.data.appraiser_hod_hr.full_name
          this.appraiser_hr_position = response.data.data.appraiser_hod_hr.position
        } else {
          this.appraiser_hr_name = '-'
          this.appraiser_hr_position = '-'
        }

        if (response.data.data.supervisor === null) {
          this.svname = '-'
          this.svposition = '-'
          this.comment2 = ''
          this.tarikh2 = '-'
        }
        // if (response.data.data.appraiser_sv_hod === null) {
        //   this.appraiser_hod_name = '-'
        //   this.appraiser_hod_position = '-'
        //   this.comment3 = ''
        //   this.tarikh3 = '-'
        // }
        // if (response.data.data.appraiser_sv_hod === null){
        //   this.appraiser_hod_hr_name = response.data.data.appraiser_sv_hod !== null ? response.data.data.appraiser_sv_hod.full_name : '-'
        //   this.appraiser_hod_hr_position = response.data.data.appraiser_sv_hod !== null ? response.data.data.appraiser_sv_hod.position : '-'
        // }
      })
    },
    getSuggestion(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/suggestions/get_by_emp?cycle_id=${this.id}`, data).then(response => {
        this.res = response.data.data
        if (response.data.data.length !== 0) {
          this.suggestion = response.data.data[0].suggestion.split("\n")
        } else {
          this.suggestion = '-'
        }
      })
    },

    getAppraiserComment(val){
      const data = new FormData()

      data.append('performance_id', this.performanceID)
      if (val === 'previous'){
        data.append('previous', 1)
      } else if (val === 'next'){
        data.append('next', 1)
      } else {
        // console.log('current')
      }

      this.$axios.post(`${this.$baseUrl}/myperformances/get_appraiser_comments`, data).then(response => {
        // this.appComment = response.data.data

        this.appraiser_comment = response.data.data
        // console.log("comment appraiser", response)
        if (this.appraiser_comment !== null){
          this.performanceID = this.appraiser_comment.performance_id
          this.comment2 = this.appraiser_comment.comment === null ? `${this.$t('No Comment')}` : this.appraiser_comment.comment
          this.tarikh2 = this.appraiser_comment.date !== null ? this.appraiser_comment.date.slice(0, 10) : '-'
        } else {
          // console.log('null')
        }
        // if (this.appraiser_comment.comment === null){
        //   this.comment2 = 'No Comment'
        // }

        this.next_comment = this.appraiser_comment === null ? '' : this.appraiser_comment.has_next
        // console.log("next", this.appraiser_comment.has_next)

        this.previous_comment = this.appraiser_comment === null ? '' : this.appraiser_comment.has_previous
        // console.log("previous", this.appraiser_comment.has_previous)
      })
      // .catch(error => {
      //   console.log('hello', error)
      // })
    },
    // eslint-disable-next-line no-unused-vars
    multipleComments(currentID = this.performanceID, reloop = false) {
      if (!reloop) {
        this.comments = []
      }
      const data = new FormData()

      data.append('performance_id', this.performanceID)
      data.append('previous', 1)

      const params = new URLSearchParams()

      params.append('performance_id', currentID)
      if (reloop) {
        params.append('previous', 1)
      }

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/myperformances/get_appraiser_comments`,
        params,
      }

      this.$axios(config)
        // eslint-disable-next-line no-loop-func, arrow-body-style
        .then(response => {
          // console.log('comments:', response.data.data)
          const result = response.data.data

          this.comments.push(result)
          if (result.has_previous === 1) {
            this.multipleComments(result.performance_id, true)
          } else {
            this.triggerGenerateReport()
            // siniiiiiiiii
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },
    triggerGenerateReport() {
      // console.log('will trigger here: ', this.comments)
      this.showComments()
    },
    updateComment() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.toggleInput()
          const data = new FormData()
          data.append('comment', this.comment)
          data.append('suggestion', this.suggestion)

          // console.log(this.comment, this.suggestion)

          this.$axios.post(`${this.$baseUrl}/myperformances/comment?cycle_id=${this.id}`, data).then(() => {
            // console.log(response)
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully saved')}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            this.isSave = false
          }).catch(error => {
            this.toggleInput()
            this.isSave = false
            // console.log(error.response)
            // this.disableInput = true
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: error.response.data.message,
                  icon: "XIcon",
                  variant: "danger",
                  iconBg: '#e80202',
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'error',
              },
            )
          })
        } else {
          this.isSave = false
        }
      })
    },
    toggleInput() {
      this.disableInput = !this.disableInput
    },
    submitPerformance(){
      // console.log('sini perff id', this.id)
      const data = new FormData()

      data.append('performance_id[]', this.id)
      data.append('submit', 1)

      // // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }
      this.$axios.post(`${this.$baseUrl}/performance/approve`, data).then(() => {
        this.$bvModal.hide('submit-performance')
        this.$emit('submit-all')
        this.getData()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully submitted')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        // this.$router.push({ name: 'my-performance', params: { id: this.performanceID } })
      }).catch(error => {
        // console.log('ini error', error.response.data.data)
        const tempRes = error.response.data.data
        const msg = []
        // console.log('res', tempRes)
        tempRes.forEach(val => {
          if ((val.indexOf('_') !== -1) === true){
            const a = val.split("_")
            const b = a[1].charAt(0).toUpperCase() + a[1].slice(1)
            switch (a[0]){
              case 'p1':
                msg.push(`${`Part 1 ${b}`}`)
                break
              case 'p2':
                msg.push(`${`Part 2 ${b}`}`)
                break
              case 'p3':
                msg.push(`${`Part 3 ${b}`}`)
                break
              case 'p4':
                msg.push(`${`Part 4 ${b}`}`)
                break
              default:
                break
            }
          }
        })

        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Please complete all the performance first: ')} ${msg}`,
              // title: 'Please complete all the performance first !',
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
        // alert('meow')
        // const res = error.message
        // const resData = error.response.data

        // const result = error.response.data

        // this.$toast(
        //   {
        //     component: SAJToast,
        //     props: {
        //       // title: error.response.data.message,
        //       title: 'Please complete all the performance first !',
        //       icon: 'XIcon',
        //       iconBg: '#e80202',
        //       variant: 'success',
        //       titleColor: '#000',
        //     },
        //   },
        //   {
        //     position: "top-right",
        //     type: 'info',
        //   },
        // )
      })
    },

    agreePerformance(){
      // console.log('sini perff id', this.id)
      const data = new FormData()

      data.append('performance_id[]', this.id)
      data.append('agree', 1)

      // // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }
      this.$axios.post(`${this.$baseUrl}/performance/approve`, data).then(() => {
        this.$bvModal.hide('agree-performance')
        this.getData()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully agreed')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        // this.$router.push({ name: 'my-performance', params: { id: this.performanceID } })
      })

      // .catch(error => {
      //   console.log('hello', error)
      // })
    },
    checkComment() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.show("submit-performance")
        }
      })
    },
  },
}
</script>
