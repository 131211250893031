<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->
        <b-col md="12">
          <b-card
            class="text-center"
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              height: auto;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                {{ $t("Part") }} 3
              </span>
            </b-card-header>

            <hr>

            <b-tabs
              nav-class="saj-title m-0"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <!-- Latihan dan Pembangunan -->

              <b-tab
                :title="$t('3a. Training and Development')"
                active
              >
                <!-- <div
                  v-if="roles.isTopManagement"
                  class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                >
                  {{
                    training.total_score !== null ? training.total_score : 0
                  }}/{{ weightages.training + weightages.self_learning }}
                  %
                </div> -->
                <div
                  class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  v-if="training.hasTraining == 1"
                >
                  {{
                    training.total_score !== null ? training.total_score : 0
                  }}/{{ weightages.training + weightages.self_learning }}
                  %
                </div>
                <div
                  fluid
                  class="bv-example-row"
                >
                  <div
                    v-if="
                      training.submit_at === null
                    "
                    class="d-flex justify-content-end"
                  >
                    <b-col
                      style="display: flex; justify-content: flex-end"
                    ><b-button
                      variant="primary"
                      class="saj-title mb-1"
                      style="margin-right: 10px"
                      @click="addTraining()"
                    >
                      {{
                        training.hasTraining == 1
                          ? $t("No Training Attended")
                          : $t("Add Training")
                      }}
                    </b-button>
                    </b-col>
                  </div>
                  <div v-if="training.hasTraining == 1">
                    <div
                      v-if="status.toLowerCase() !== 'completed'"
                      class="d-flex justify-content-end"
                    >
                      <b-col
                        style="display: flex; justify-content: flex-end"
                      ><b-button
                         :style="approved_at === null ? '' : 'display: none;'"
                         variant="primary"
                         class="saj-title"
                         style="margin-right: 10px"
                         @click="isEditTrain = !isEditTrain"
                       >
                         {{ isEditTrain ? $t("Cancel") : $t("Edit") }}
                       </b-button>
                        <b-button
                          v-if="isEditTrain"
                          class="saj-title"
                          style="
                            margin-right: 9px;
                            background: #0b103c !important;
                            border-color: #0b103c !important;
                          "
                          @click="validateForm()"
                        >
                          {{ $t("Save") }}
                        </b-button>
                        <b-button
                          v-if="isSaveTraining"
                          class="saj-title"
                          style="
                            margin-right: 9px;
                            background: #0b103c !important;
                            border-color: #0b103c !important;
                          "
                        >
                          <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
                        </b-button>
                      </b-col>
                    </div>

                    <div
                      class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                      style="
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.4px;
                        color: #000000;
                        text-align: start !important;
                      "
                    >
                      {{
                        $t(
                          "List The Program and Training That Involved during the evaluation period"
                        )
                      }}.
                    </div>
                    <b-col
                      v-if="roles.isTopManagement"
                      cols="12"
                      class="justify-content-end m-0 p-0"
                    >
                      <div
                        class="saj-title mt-2 mr-1 mb-1 d-flex justify-content-end"
                      >
                        {{ training.program_score }}/{{ weightages.training + weightages.self_learning }} %
                      </div>
                    </b-col>
                    <div class="m-2">
                      <b-form
                        ref="form"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                      >
                        <!-- Row Loop -->
                        <template v-for="(training, i) in trainingList">
                          <validation-observer ref="part3ValidationUpdate">
                            <b-row
                              :id="training.id"
                              :key="'a' + i"
                              ref="row"
                              class="m-0 p-2 mb-2"
                              style="
                                color: black;
                                background: #f7f7fc;
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                                  0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                                border-radius: 20px;
                                height: 100%;
                              "
                            >
                              {{ i + 1 + "." }}

                              <!-- Tajuk Kursus / Program -->

                              <div class="saj-title col-md-4">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Program Title')"
                                  :rules="'required'"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Program Title')"
                                    label-for="tajuk-kursus"
                                  >
                                    <b-form-input
                                      v-model="training.program_name"
                                      class="saj-text"
                                      type="text"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Tarikh Mula -->
                              <div class="saj-title col-md-2">
                                <b-form-group
                                  label-size="lg"
                                  :label="$t('Start Date')"
                                  label-for="mula"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Start Date')"
                                    :rules="'required'"
                                  >
                                    <div
                                      :style="
                                        !isEditTrain
                                          ? `background-color: #efefef; border-radius: 0.357rem`
                                          : `background-color: white; border-radius: 0.357rem`
                                      "
                                    >
                                      <flat-pickr
                                        v-model="training.start_date"
                                        class="form-control saj-text"
                                        :config="{
                                          dateFormat: 'Y-m-d',
                                          altInput: true,
                                          altFormat: 'd-m-Y',
                                        }"
                                        :disabled="!isEditTrain"
                                      />
                                    </div>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </div>

                              <!-- Tarikh Akhir -->
                              <div class="saj-title col-md-2">
                                <b-form-group
                                  label-size="lg"
                                  :label="$t('End Date')"
                                  label-for="akhir"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('End Date')"
                                    :rules="'required'"
                                  >
                                    <div
                                      :style="
                                        !isEditTrain
                                          ? `background-color: #efefef; border-radius: 0.357rem`
                                          : `background-color: white; border-radius: 0.357rem`
                                      "
                                    >
                                      <flat-pickr
                                        v-model="training.end_date"
                                        class="form-control saj-text"
                                        :config="{
                                          dateFormat: 'Y-m-d',
                                          altInput: true,
                                          altFormat: 'd-m-Y',
                                        }"
                                        :disabled="!isEditTrain"
                                      />
                                    </div>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </div>

                              <!-- Number of hours -->
                              <div class="saj-title col-md-3 mr-1">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Number of Hours')"
                                  :rules="'required'"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Number of hours')"
                                    label-for="jumlah-jam"
                                  >
                                    <b-form-input
                                      v-model="training.hour"
                                      class="saj-text"
                                      type="number"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Add and Remove Button -->
                              <div v-if="isEditTrain">
                                <feather-icon
                                  ref="training-items"
                                  style="
                                    width: 30px;
                                    height: 30px;
                                    color: #c5c7cd;
                                    margin-top: 50px;
                                  "
                                  icon="MinusCircleIcon"
                                  @click="
                                    showAlertModal('training', training.id)
                                  "
                                />
                              </div>
                              <b-row
                                class="saj-text d-flex justify-content-start align-items-center m-0 ml-3"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                <div
                                  v-if="training.file === null"
                                  class="saj-text row align-items-center"
                                  style=""
                                >
                                  <feather-icon
                                    class="mr-1"
                                    icon="PaperclipIcon"
                                    size="16"
                                  />
                                  {{ $t("No File") }}
                                </div>
                                <div
                                  v-if="training.file !== null"
                                  class="saj-text row align-items-center"
                                  style=""
                                >
                                  <feather-icon
                                    class="mr-1"
                                    icon="PaperclipIcon"
                                    size="16"
                                  />
                                  <a
                                    :href="training.file.url"
                                    target="_blank"
                                  >
                                    {{ training.file.name }}
                                  </a>
                                </div>
                              </b-row>
                              <b-row
                                v-if="isEditTrain"
                                class="col-12 p-1 m-0 d-flex justify-content-start"
                              >
                                <b-col>
                                  <b-form-file
                                    v-model="training.fileToUpload"
                                    type="file"
                                    :disabled="!isEditTrain"
                                    class="saj-text mb-1 mt-1 d-flex justify-content-start"
                                    :browse-text="$t('Choose File')"
                                    :placeholder="$t('No file choosen')"
                                    style="width: 30%; text-align: start"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                              <b-row
                                class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                {{ $t("Self Score") }}:
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Self Score')"
                                  :rules="'required'"
                                >
                                  <b-form-rating
                                    v-model="training.self_score"
                                    show-clear
                                    style="background: none !important"
                                    no-border
                                    show-value
                                    inline
                                    color="#DFB300"
                                    stars="6"
                                    size="lg"
                                    :disabled="!isEditTrain"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <span
                                  style="margin-top: 5px; margin-right: 15px"
                                >
                                  <h5 v-if="training.self_score == '1'">
                                    {{
                                      isEnglish
                                        ? rating[5].definition_en
                                        : rating[5].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="training.self_score == '2'">
                                    {{
                                      isEnglish
                                        ? rating[4].definition_en
                                        : rating[4].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="training.self_score == '3'">
                                    {{
                                      isEnglish
                                        ? rating[3].definition_en
                                        : rating[3].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="training.self_score == '4'">
                                    {{
                                      isEnglish
                                        ? rating[2].definition_en
                                        : rating[2].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="training.self_score == '5'">
                                    {{
                                      isEnglish
                                        ? rating[1].definition_en
                                        : rating[1].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="training.self_score == '6'">
                                    {{
                                      isEnglish
                                        ? rating[0].definition_en
                                        : rating[0].definition_my
                                    }}
                                  </h5>
                                </span>
                              </b-row>
                              <b-row
                                class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                <div class="mr-1">
                                  {{ $t("Manager Score") }}:
                                  {{ training.manager_score }}/6
                                </div>
                              </b-row>
                              <b-col cols="12">
                                <hr>
                              </b-col>
                            </b-row>
                          </validation-observer>
                        </template>
                      </b-form>
                    </div>
                    <div class="m-2">
                      <b-form
                        ref="form"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                      >
                        <template v-for="(item, idx) in items">
                          <!-- Row Loop -->
                          <validation-observer ref="part3ValidationNew">
                            <b-row
                              :id="item.id"
                              :key="idx"
                              ref="row"
                              class="m-0 p-2 mb-2"
                            >
                              <!-- Tajuk Kursus / Program -->
                              <div
                                class="saj-title col-md-4"
                                style="margin-left: 12px"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Program Title')"
                                  :rules="idx !== 0 ? 'required' : ''"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Program Title')"
                                    label-for="tajuk-kursus"
                                    class=""
                                  >
                                    <b-form-input
                                      v-model="item.program_name"
                                      class="saj-text"
                                      type="text"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    /> </b-form-group><small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Tarikh Mula -->
                              <div class="saj-title col-md-2">
                                <b-form-group
                                  label-size="lg"
                                  :label="$t('Start Date')"
                                  label-for="mula"
                                  class=""
                                >
                                  <div
                                    class=""
                                    :class="
                                      !isEditTrain ? 'addItem' : 'addItem2'
                                    "
                                  >
                                    <flat-pickr
                                      v-model="item.tempStart_date"
                                      :config="{
                                        dateFormat: 'Y-m-d',
                                        altInput: true,
                                        altFormat: 'd-m-Y',
                                      }"
                                      :disabled="!isEditTrain"
                                      @input="setStartDate(idx)"
                                    />
                                  </div>
                                </b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Start Date')"
                                  :rules="
                                    idx !== 0 || item.program_name.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <input
                                    v-model="item.start_date"
                                    type="hidden"
                                  >

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Tarikh Akhir -->
                              <div class="saj-title col-md-2">
                                <b-form-group
                                  label-size="lg"
                                  :label="$t('End Date')"
                                  label-for="akhir"
                                  class=""
                                >
                                  <div
                                    class=""
                                    :class="
                                      !isEditTrain ? 'addItem' : 'addItem2'
                                    "
                                  >
                                    <flat-pickr
                                      v-model="item.tempEnd_date"
                                      :config="{
                                        dateFormat: 'Y-m-d',
                                        altInput: true,
                                        altFormat: 'd-m-Y',
                                      }"
                                      :disabled="!isEditTrain"
                                      @input="setEndDate(idx)"
                                    />
                                  </div>
                                </b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('End Date')"
                                  :rules="
                                    idx !== 0 || item.program_name.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <input
                                    v-model="item.end_date"
                                    type="hidden"
                                  >
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Jumlah Jam -->
                              <div class="saj-title col-md-3 mr-1">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Number of Hours')"
                                  :rules="
                                    idx !== 0 || item.program_name.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Number of hours')"
                                    label-for="jumlah-jam"
                                  >
                                    <b-form-input
                                      v-model="item.hour"
                                      class="saj-text"
                                      type="number"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </div>

                              <!-- Add and Remove Button -->
                              <div
                                v-if="isEditTrain"
                                class="mt-2 p-0 d-flex align-items-center justify-content-around"
                              >
                                <feather-icon
                                  v-model="tambah"
                                  style="
                                    width: 30px;
                                    height: 30px;
                                    color: #c5c7cd;
                                  "
                                  icon="PlusCircleIcon"
                                  @click="repeateAgain"
                                />
                                <feather-icon
                                  v-if="idx !== 0"
                                  style="
                                    width: 30px;
                                    height: 30px;
                                    color: #c5c7cd;
                                  "
                                  icon="MinusCircleIcon"
                                  @click="removeItem(idx)"
                                />
                              </div>
                              <validation-provider
                                #default="{ errors }"
                                :name="$t('File')"
                                :rules="
                                  idx !== 0 || item.program_name.length > 0
                                    ? 'required'
                                    : ''
                                "
                              >
                                <b-row
                                  class="col-12 p-1 m-0 d-flex justify-content-start"
                                >
                                  <b-form-file
                                    v-model="item.fileToUpload"
                                    type="file"
                                    class="saj-text m-0 ml-1 d-flex justify-content-start"
                                    :disabled="!isEditTrain"
                                    :browse-text="$t('Choose File')"
                                    :placeholder="$t('No file choosen')"
                                    style="width: 100%; text-align: start"
                                    @change="onFileChange"
                                  />
                                </b-row>
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                              <b-row
                                class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                {{ $t("Self Score") }}:
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Self Score')"
                                  :rules="
                                    idx !== 0 || item.program_name.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <b-form-rating
                                    v-model="item.self_score"
                                    show-clear
                                    style="background: none !important"
                                    no-border
                                    show-value
                                    inline
                                    color="#DFB300"
                                    stars="6"
                                    size="lg"
                                    :disabled="!isEditTrain"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <span
                                  style="margin-top: 5px; margin-right: 15px"
                                >
                                  <h5 v-if="item.self_score == '1'">
                                    {{
                                      isEnglish
                                        ? rating[5].definition_en
                                        : rating[5].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="item.self_score == '2'">
                                    {{
                                      isEnglish
                                        ? rating[4].definition_en
                                        : rating[4].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="item.self_score == '3'">
                                    {{
                                      isEnglish
                                        ? rating[3].definition_en
                                        : rating[3].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="item.self_score == '4'">
                                    {{
                                      isEnglish
                                        ? rating[2].definition_en
                                        : rating[2].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="item.self_score == '5'">
                                    {{
                                      isEnglish
                                        ? rating[1].definition_en
                                        : rating[1].definition_my
                                    }}
                                  </h5>
                                  <h5 v-else-if="item.self_score == '6'">
                                    {{
                                      isEnglish
                                        ? rating[0].definition_en
                                        : rating[0].definition_my
                                    }}
                                  </h5>
                                </span>
                              </b-row>
                            </b-row>
                          </validation-observer>
                        </template>
                      </b-form>
                    </div>
                    <!-- Self Learning -->
                    <div
                      v-if="roles.isTopManagement"
                      class="mt-5"
                      style="
                        background: rgb(239, 237, 238);
                        border-radius: 20px;
                      "
                    >
                      <b-row
                        class=""
                        style="
                          letter-spacing: 0.4px;
                          color: #000000;
                          padding-top: 27px;
                        "
                      >
                        <b-col
                          cols="10"
                          class=""
                        >
                          <h1
                            class="ml-1 d-flex justify-content-start saj-title"
                          >
                            {{ $t("Self Learning") }}.
                          </h1>
                        </b-col>
                        <b-col
                          cols="2"
                          class="justify-content-end"
                        >
                          <div
                            class="saj-title mr-1 mb-1 d-flex justify-content-end"
                          >
                            {{ training.self_learn_score }}/{{
                              weightages.self_learning
                            }}
                            %
                          </div>
                        </b-col>
                      </b-row>
                      <div class="m-2 text-center">
                        <b-form
                          ref="form"
                          :style="{ height: trHeight }"
                          class="repeater-form"
                          @submit.prevent="repeateAgain4"
                        >
                          <!-- Row Loop -->
                          <template
                            v-for="(existitem4, i) in selfLearnTrainingList"
                          >
                            <validation-observer ref="part3ValidationUpdate">
                              <b-row
                                :id="existitem4.id"
                                :key="'a' + i"
                                ref="row"
                                class="m-0 p-2 mb-2"
                                style="
                                  background: #f7f7fc;
                                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                                    0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                                  border-radius: 20px;
                                "
                              >
                                {{ i + 1 + "." }}
                                <!-- Tajuk Kursus / Program -->
                                <div class="col-md-4">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Program Title')"
                                    :rules="'required'"
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="$t('Program Title')"
                                      label-for="tajuk-kursus"
                                    >
                                      <b-form-input
                                        v-model="existitem4.program_name"
                                        class="saj-text"
                                        type="text"
                                        :disabled="!isEditTrain"
                                        placeholder=""
                                      />
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </div>

                                <!-- Tarikh Mula -->
                                <div class="col-md-2">
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Start Date')"
                                    label-for="mula"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Start Date')"
                                      :rules="'required'"
                                    >
                                      <div
                                        :style="
                                          !isEditTrain
                                            ? `background-color: #efefef; border-radius: 0.357rem`
                                            : `background-color: white; border-radius: 0.357rem`
                                        "
                                      >
                                        <flat-pickr
                                          v-model="existitem4.start_date"
                                          class="form-control saj-text"
                                          :config="{
                                            dateFormat: 'Y-m-d',
                                            altInput: true,
                                            altFormat: 'd-m-Y',
                                          }"
                                          :disabled="!isEditTrain"
                                        />
                                      </div>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </div>

                                <!-- Tarikh Akhir -->
                                <div class="col-md-2">
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('End Date')"
                                    label-for="akhir"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('End Date')"
                                      :rules="'required'"
                                    >
                                      <div
                                        :style="
                                          !isEditTrain
                                            ? `background-color: #efefef; border-radius: 0.357rem`
                                            : `background-color: white; border-radius: 0.357rem`
                                        "
                                      >
                                        <flat-pickr
                                          v-model="existitem4.end_date"
                                          class="form-control saj-text"
                                          :config="{
                                            dateFormat: 'Y-m-d',
                                            altInput: true,
                                            altFormat: 'd-m-Y',
                                          }"
                                          :disabled="!isEditTrain"
                                        />
                                      </div>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </div>
                                <!-- Jumlah Jam -->
                                <div class="col-md-3 mr-1">
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Number of hours')"
                                    label-for="jumlah-jam"
                                  >
                                    <b-form-input
                                      v-model="existitem4.hour"
                                      class="saj-text"
                                      type="number"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                </div>
                                <!-- Self Score Summary Comment -->
                                <div
                                  class="col-md-11 mr-1"
                                  style="margin-left: 14px"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Summary Comment')"
                                    label-for="komen"
                                  >
                                    <b-form-input
                                      v-model="existitem4.summary_comment"
                                      class="saj-text"
                                      type="text"
                                      placeholder=""
                                      :disabled="!isEditTrain"
                                    />
                                  </b-form-group>
                                </div>

                                <!-- Add and Remove Button -->
                                <div v-if="isEditTrain">
                                  <feather-icon
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      color: #c5c7cd;
                                      margin-top: 50px;
                                    "
                                    icon="MinusCircleIcon"
                                    @click="
                                      showAlertModal('self', existitem4.id4)
                                    "
                                  />
                                </div>
                                <b-row
                                  class="d-flex justify-content-start align-items-center m-0 ml-3 saj-title"
                                >
                                  <div
                                    v-if="existitem4.file === null"
                                    class="row align-items-center saj-text"
                                    style="font-size: 12px"
                                  >
                                    <feather-icon
                                      class="mr-1"
                                      icon="PaperclipIcon"
                                      size="16"
                                    />
                                    {{ $t("No File") }}
                                  </div>
                                  <div
                                    v-if="existitem4.file !== null"
                                    class="row align-items-center saj-text"
                                    style=""
                                  >
                                    <feather-icon
                                      class="mr-1"
                                      icon="PaperclipIcon"
                                      size="16"
                                    />
                                    <a
                                      :href="existitem4.file.url"
                                      target="_blank"
                                    >
                                      {{ existitem4.file.name }}
                                    </a>
                                  </div>
                                </b-row>
                                <b-row
                                  class="col-12 p-1 d-flex justify-content-start ml-1"
                                >
                                  <b-form-file
                                    v-model="existitem4.fileToUpload"
                                    type="file"
                                    class="mt-1 saj-text"
                                    :disabled="!isEditTrain"
                                    :browse-text="$t('Choose File')"
                                    :placeholder="$t('No file choosen')"
                                    style="width: 30%; text-align: start"
                                    @change="onFileChange"
                                  />
                                </b-row>
                                <b-row
                                  class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                  style="
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.1em;
                                    color: #000000;
                                    width: 100%;
                                  "
                                >
                                  {{ $t("Self Score") }}:
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Self Score')"
                                    :rules="'required'"
                                  >
                                    <b-form-rating
                                      v-model="existitem4.self_score"
                                      show-clear
                                      style="background: none !important"
                                      no-border
                                      show-value
                                      inline
                                      color="#DFB300"
                                      stars="6"
                                      size="lg"
                                      :disabled="!isEditTrain"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                  <span
                                    style="margin-top: 5px; margin-right: 15px"
                                  >
                                    <h5 v-if="existitem4.self_score == '1'">
                                      {{
                                        isEnglish
                                          ? rating[5].definition_en
                                          : rating[5].definition_my
                                      }}
                                    </h5>
                                    <h5
                                      v-else-if="existitem4.self_score == '2'"
                                    >
                                      {{
                                        isEnglish
                                          ? rating[4].definition_en
                                          : rating[4].definition_my
                                      }}
                                    </h5>
                                    <h5
                                      v-else-if="existitem4.self_score == '3'"
                                    >
                                      {{
                                        isEnglish
                                          ? rating[3].definition_en
                                          : rating[3].definition_my
                                      }}
                                    </h5>
                                    <h5
                                      v-else-if="existitem4.self_score == '4'"
                                    >
                                      {{
                                        isEnglish
                                          ? rating[2].definition_en
                                          : rating[2].definition_my
                                      }}
                                    </h5>
                                    <h5
                                      v-else-if="existitem4.self_score == '5'"
                                    >
                                      {{
                                        isEnglish
                                          ? rating[1].definition_en
                                          : rating[1].definition_my
                                      }}
                                    </h5>
                                    <h5
                                      v-else-if="existitem4.self_score == '6'"
                                    >
                                      {{
                                        isEnglish
                                          ? rating[0].definition_en
                                          : rating[0].definition_my
                                      }}
                                    </h5>
                                  </span>
                                </b-row>
                                <b-row
                                  class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                  style="
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.1em;
                                    color: #000000;
                                    width: 100%;
                                  "
                                >
                                  <div class="mr-1">
                                    {{ $t("Manager Score") }}:
                                    {{ existitem4.manager_score }}/6
                                  </div>
                                </b-row>
                                <b-col cols="12">
                                  <hr>
                                </b-col>
                              </b-row>
                            </validation-observer>
                          </template>
                        </b-form>
                      </div>
                      <!-- Self Learning -->
                      <!-- ------------------------------------------------------------------------- -->
                      <!-- Self Learning Looping -->
                      <div class="m-2 text-center">
                        <b-form
                          ref="form"
                          :style="{ height: trHeight }"
                          class="repeater-form"
                          @submit.prevent="repeateAgain4"
                        >
                          <template v-for="(item4, idx) in items4">
                            <!-- Row Loop -->
                            <validation-observer ref="part3ValidationNew">
                              <b-row
                                :id="item4.id"
                                :key="idx"
                                ref="row"
                                class="m-0 p-2 mb-2"
                              >
                                <!-- Tajuk Kursus / Program -->
                                <div
                                  class="col-md-4"
                                  style="margin-left: 12px"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Program Title')"
                                    :rules="idx !== 0 ? 'required' : ''"
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="$t('Program Title')"
                                      label-for="tajuk-kursus"
                                    >
                                      <b-form-input
                                        v-model="item4.program_name"
                                        class="saj-text"
                                        type="text"
                                        :disabled="!isEditTrain"
                                        placeholder=""
                                      />
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </div>

                                <!-- Tarikh Mula -->
                                <div class="col-md-2">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Start Date')"
                                    :rules="
                                      idx !== 0 || item4.program_name.length > 0
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="$t('Start Date')"
                                      label-for="mula"
                                    >
                                      <div
                                        class=""
                                        :class="
                                          !isEditTrain ? 'addItem' : 'addItem2'
                                        "
                                      >
                                        <flat-pickr
                                          v-model="item4.start_date"
                                          :config="{
                                            dateFormat: 'Y-m-d',
                                            altInput: true,
                                            altFormat: 'd-m-Y',
                                          }"
                                          :disabled="!isEditTrain"
                                        />
                                      </div>
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </div>

                                <!-- Tarikh Akhir -->
                                <div class="col-md-2">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('End Date')"
                                    :rules="
                                      idx !== 0 || item4.program_name.length > 0
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="$t('End Date')"
                                      label-for="akhir"
                                    >
                                      <div
                                        class=""
                                        :class="
                                          !isEditTrain ? 'addItem' : 'addItem2'
                                        "
                                      >
                                        <flat-pickr
                                          v-model="item4.end_date"
                                          :config="{
                                            dateFormat: 'Y-m-d',
                                            altInput: true,
                                            altFormat: 'd-m-Y',
                                          }"
                                          :disabled="!isEditTrain"
                                        />
                                      </div>
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </div>
                                <!-- Jumlah Jam -->
                                <div class="col-md-3 mr-1">
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Number of hours')"
                                    label-for="jumlah-jam"
                                  >
                                    <b-form-input
                                      v-model="item4.hour"
                                      class="saj-text"
                                      type="number"
                                      :disabled="!isEditTrain"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                </div>
                                <!-- Self Score Summary Comment -->
                                <div
                                  class="col-md-10 mr-1"
                                  style="margin-left: 12px"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Summary Comment')"
                                    label-for="komen"
                                  >
                                    <b-form-input
                                      v-model="item4.summary_comment"
                                      class="saj-text"
                                      type="text"
                                      placeholder=""
                                      :disabled="!isEditTrain"
                                    />
                                  </b-form-group>
                                </div>

                                <!-- Add and Remove Button -->
                                <div
                                  v-if="isEditTrain"
                                  class="mt-2 p-0 d-flex align-items-center justify-content-around"
                                >
                                  <feather-icon
                                    v-model="tambah"
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      color: #c5c7cd;
                                    "
                                    icon="PlusCircleIcon"
                                    @click="repeateAgain4"
                                  />
                                  <feather-icon
                                    v-if="idx !== 0"
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      color: #c5c7cd;
                                    "
                                    icon="MinusCircleIcon"
                                    @click="removeItem4(idx)"
                                  />
                                </div>
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('File')"
                                  :rules="
                                    idx !== 0 || item4.program_name.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <b-row
                                    class="col-12 p-1 m-0 d-flex justify-content-start"
                                  >
                                    <b-form-file
                                      v-model="item4.fileToUpload"
                                      type="file"
                                      class="saj-text m-0 ml-1 d-flex justify-content-start"
                                      :disabled="!isEditTrain"
                                      :browse-text="$t('Choose File')"
                                      :placeholder="$t('No file choosen')"
                                      style="width: 100%; text-align: start"
                                      @change="onFileChange"
                                    />
                                  </b-row>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <b-row
                                  class="self-score d-flex justify-content-end align-items-center m-0 col-12"
                                  style="
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.1em;
                                    color: #000000;
                                    width: 100%;
                                  "
                                >
                                  {{ $t("Self Score") }}:
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Self Score')"
                                    :rules="
                                      idx !== 0 || item4.program_name.length > 0
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <b-form-rating
                                      v-model="item4.self_score"
                                      show-clear
                                      style="background: none !important"
                                      no-border
                                      show-value
                                      inline
                                      color="#DFB300"
                                      stars="6"
                                      size="lg"
                                      :disabled="!isEditTrain"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                  <span
                                    style="margin-top: 5px; margin-right: 15px"
                                  >
                                    <h5 v-if="item4.self_score == '1'">
                                      {{
                                        isEnglish
                                          ? rating[5].definition_en
                                          : rating[5].definition_my
                                      }}
                                    </h5>
                                    <h5 v-else-if="item4.self_score == '2'">
                                      {{
                                        isEnglish
                                          ? rating[4].definition_en
                                          : rating[4].definition_my
                                      }}
                                    </h5>
                                    <h5 v-else-if="item4.self_score == '3'">
                                      {{
                                        isEnglish
                                          ? rating[3].definition_en
                                          : rating[3].definition_my
                                      }}
                                    </h5>
                                    <h5 v-else-if="item4.self_score == '4'">
                                      {{
                                        isEnglish
                                          ? rating[2].definition_en
                                          : rating[2].definition_my
                                      }}
                                    </h5>
                                    <h5 v-else-if="item4.self_score == '5'">
                                      {{
                                        isEnglish
                                          ? rating[1].definition_en
                                          : rating[1].definition_my
                                      }}
                                    </h5>
                                    <h5 v-else-if="item4.self_score == '6'">
                                      {{
                                        isEnglish
                                          ? rating[0].definition_en
                                          : rating[0].definition_my
                                      }}
                                    </h5>
                                  </span>
                                </b-row>
                                <b-col cols="12" />
                              </b-row>
                            </validation-observer>
                          </template>
                        </b-form>
                      </div>
                    </div>
                    <!-- Self Learning End -->
                  </div>
                </div>
              </b-tab>
              <b-tab
                v-if="contributions.internal_industry_status == 'enable'"
                :title="`3b. ${$t('Internal / Industrial Involvement')}`"
              >
                <div
                  class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                >
                  {{
                    contributions.total_score !== null
                      ? contributions.total_score
                      : 0
                  }}/{{ contributions.internal_industry_involement }}
                  %
                </div>
                <b-col style="margin-bottom: 10px; margin-left: 34rem" />
                <div
                  fluid
                  class="bv-example-row"
                >
                  <div
                    v-if="contributions.hasContribution === 1"
                    class="d-flex justify-content-end"
                  >
                    <b-col style="display: flex; justify-content: flex-end">
                      <b-button
                        :style="approved_at === null ? '' : 'display: none;'"
                        variant="primary"
                        class="saj-title"
                        style="margin-right: 10px"
                        @click="isEditCont = !isEditCont"
                      >
                        {{ isEditCont ? $t("Cancel") : $t("Edit") }}
                      </b-button>
                      <b-button
                        v-if="isEditCont"
                        class="saj-title"
                        style="
                          margin-right: 9px;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
                        @click="validateFormPartB()"
                      >
                        {{ $t("Save") }}
                      </b-button>
                      <b-button
                        v-if="isSaveContr"
                        class="saj-title"
                        style="
                          margin-right: 9px;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
                      >
                        <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
                      </b-button>
                    </b-col>
                  </div>
                  <div
                    class="saj-text m-0 ml-1 mt-2 p-0"
                    style="text-align: start !important"
                  >
                    {{
                      $t(
                        "List the involvement of internal/industry involvement in any association or body recognized by the laws and regulations of Malaysia"
                      )
                    }},({{ $t("except political parties") }}).
                  </div>
                  <!-- Box 2 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-form
                        ref="form"
                        v-model="tambah"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain3"
                      >
                        <template v-for="(cont, i) in contributionList">
                          <validation-observer ref="part3BValidationUpdate">
                            <!-- Row Loop -->
                            <b-row
                              :id="cont.id"
                              :key="'c' + i"
                              ref="row"
                            >
                              <div class="ml-1">
                                {{ i + 1 + "." }}
                              </div>
                              <!-- Senarai Penglibatan -->
                              <b-col
                                class="saj-title"
                                md="4"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Involvement Activities')"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Involvement Activities')"
                                    label-for="senarai-penglibatan"
                                  >
                                    <b-form-input
                                      v-model="cont.involvement"
                                      class="saj-text mt-1"
                                      type="text"
                                      :disabled="!isEditCont"
                                      placeholder=""
                                    />
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-col>

                              <!-- Tarikh Penglibatan -->
                              <b-col
                                class="saj-title p-0"
                                md="2"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Date')"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Date')"
                                    label-for="tarikh penglibatan"
                                  >
                                    <div
                                      class="saj-text m-1"
                                      :style="
                                        !isEditCont
                                          ? `background-color: #efefef; border-radius: 0.357rem`
                                          : `background-color: white; border-radius: 0.357rem`
                                      "
                                    >
                                      <flat-pickr
                                        v-model="cont.date"
                                        :config="{
                                          dateFormat: 'Y-m-d',
                                          altInput: true,
                                          altFormat: 'd-m-Y',
                                        }"
                                        :disabled="!isEditCont"
                                      />
                                    </div>
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-col>

                              <!-- Jawatan -->
                              <b-col
                                class="saj-title p-0"
                                md="5"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Position')"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="
                                      $t('Position / Level of Participants')
                                    "
                                    label-for="jawatan"
                                  >
                                    <b-form-select
                                      v-model="cont.position"
                                      class="saj-text m-1"
                                      :options="jawatanoptions2"
                                      :disabled="!isEditCont"
                                    />
                                  </b-form-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-col>

                              <!-- Add and Remove Button -->
                              <b-col v-if="isEditCont">
                                <feather-icon
                                  style="
                                    width: 30px;
                                    height: 30px;
                                    color: #c5c7cd;
                                    margin-top: 62px;
                                  "
                                  icon="MinusCircleIcon"
                                  @click="showAlertModal('internal', cont.id2)"
                                />
                              </b-col>
                              <b-row
                                class="saj-text d-flex justify-content-start align-items-center m-0 ml-3"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                <div
                                  v-if="cont.file === null"
                                  class="saj-text row align-items-center m-0"
                                  style=""
                                >
                                  <feather-icon
                                    class="mr-1"
                                    icon="PaperclipIcon"
                                    size="16"
                                  />
                                  {{ $t("No File") }}
                                </div>
                                <div
                                  v-else
                                  class="saj-text row align-items-center m-0"
                                  style=""
                                >
                                  <feather-icon
                                    class="mr-1"
                                    icon="PaperclipIcon"
                                    size="16"
                                  />
                                  <a
                                    :href="cont.file.url"
                                    target="_blank"
                                  >
                                    {{ cont.file.name }}
                                  </a>
                                </div>
                              </b-row>

                              <!-- Lampiran -->
                              <b-row
                                class="col-12 p-0 m-0 ml-3 d-flex justify-content-start"
                              >
                                <b-form-file
                                  v-model="cont.fileToUpload"
                                  type="file"
                                  class="saj-text mb-1 mt-2"
                                  :disabled="!isEditCont"
                                  :browse-text="$t('Choose File')"
                                  :placeholder="$t('No file choosen')"
                                  style="width: 30%; text-align: start"
                                  @change="onFileChange"
                                />
                              </b-row>
                              <b-row
                                class="self-score d-flex justify-content-end align-items-center m-0 mr-3"
                                style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 24px;
                                  letter-spacing: 0.1em;
                                  color: #000000;
                                  width: 100%;
                                "
                              >
                                {{ $t("Self Score") }}:
                                <!-- {{ cont.self_score }}/6 -->

                                {{ getUserSelfScore2(cont.position) }}
                                /6
                              </b-row>

                              <b-col cols="12">
                                <hr>
                              </b-col>
                            </b-row>
                            <!-- </b-form> -->
                          </validation-observer>
                        </template>
                      </b-form>
                      <div>
                        <b-form
                          ref="form"
                          v-model="tambah"
                          :style="{ height: trHeight }"
                          class="repeater-form"
                          @submit.prevent="repeateAgain3"
                        >
                          <template v-for="(item3, idx) in items3">
                            <validation-observer ref="part3BValidationNew">
                              <!-- Row Loop -->
                              <b-row
                                :id="item3.id"
                                :key="item3.id"
                                ref="row"
                              >
                                <!-- Senarai Penglibatan -->
                                <b-col
                                  class="saj-title ml-2"
                                  md="4"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Involvement Activities')"
                                    :rules="idx !== 0 ? 'required' : ''"
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="$t('Involvement Activities')"
                                      label-for="senarai-penglibatan"
                                    >
                                      <b-form-input
                                        v-model="item3.involvement2"
                                        class="saj-text mt-1"
                                        type="text"
                                        :disabled="!isEditCont"
                                        placeholder=""
                                      />
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>

                                <!-- Tarikh Penglibatan -->
                                <b-col
                                  class="saj-title p-0"
                                  md="2"
                                >
                                  <b-form-group
                                    label-size="lg"
                                    :label="$t('Date')"
                                    label-for="tarikh penglibatan"
                                  >
                                    <div
                                      class="saj-text m-1"
                                      :style="
                                        !isEditCont
                                          ? `background-color: #efefef; border-radius: 0.357rem`
                                          : `background-color: white; border-radius: 0.357rem`
                                      "
                                    >
                                      <flat-pickr
                                        v-model="item3.tempDate2"
                                        :config="{
                                          dateFormat: 'Y-m-d',
                                          altInput: true,
                                          altFormat: 'd-m-Y',
                                        }"
                                        :disabled="!isEditCont"
                                        @input="setDate2(idx)"
                                      />
                                    </div>
                                  </b-form-group>
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Date')"
                                    :rules="
                                      idx !== 0 || item3.involvement2.length > 0
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <input
                                      v-model="item3.date2"
                                      type="hidden"
                                    >
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>

                                <!-- Jawatan -->
                                <b-col
                                  class="saj-title p-0 mr-1"
                                  md="5"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="$t('Position')"
                                    :rules="
                                      idx !== 0 || item3.involvement2.length > 0
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <b-form-group
                                      label-size="lg"
                                      :label="
                                        $t('Position / Level of Participants')
                                      "
                                      label-for="jawatan"
                                      class="container"
                                    >
                                      <b-form-select
                                        v-model="item3.position2"
                                        class="saj-text mt-1"
                                        :options="jawatanoptions2"
                                        :disabled="!isEditCont"
                                      />
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('File')"
                                  :rules="
                                    idx !== 0 || item3.involvement2.length > 0
                                      ? 'required'
                                      : ''
                                  "
                                >
                                  <b-row
                                    class="col-12 p-0 m-0 ml-3 d-flex justify-content-start"
                                  >
                                    <b-form-file
                                      v-model="item3.fileToUpload"
                                      type="file"
                                      class="saj-text mb-1 mt-2"
                                      :disabled="!isEditCont"
                                      :browse-text="$t('Choose File')"
                                      :placeholder="$t('No file choosen')"
                                      style="width: 100%; text-align: start"
                                      @change="onFileChange"
                                    />
                                  </b-row>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <!-- Add and Remove Button -->
                                <b-col
                                  v-if="isEditCont"
                                  class="d-flex justify-content-center"
                                  md="12"
                                >
                                  <feather-icon
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      color: #c5c7cd;
                                      margin-top: 63px;
                                    "
                                    icon="PlusCircleIcon"
                                    @click="repeateAgain3"
                                  />
                                  <feather-icon
                                    v-if="idx !== 0"
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      color: #c5c7cd;
                                      margin-top: 63px;
                                    "
                                    icon="MinusCircleIcon"
                                    @click="removeItem3(idx)"
                                  />
                                </b-col>

                                <b-row
                                  class="self-score d-flex justify-content-end align-items-center m-0 mr-3"
                                  style="
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.1em;
                                    color: #000000;
                                    width: 100%;
                                    text-indent: 8px;
                                  "
                                >{{ $t("Self Score") }}:
                                  <div
                                    v-if="
                                      item3.position2 == null ||
                                        item3.position2 == 0
                                    "
                                  >
                                    0
                                  </div>
                                  <div v-if="item3.position2 == 1">
                                    1
                                  </div>
                                  <div v-else-if="item3.position2 == 2">
                                    2
                                  </div>
                                  <div v-else-if="item3.position2 == 3">
                                    3
                                  </div>
                                  <div v-else-if="item3.position2 == 4">
                                    4
                                  </div>
                                  <div v-else-if="item3.position2 == 5">
                                    5
                                  </div>
                                  <div v-else-if="item3.position2 == 6">
                                    6
                                  </div>
                                  /6
                                </b-row>
                                <b-col cols="12">
                                  <hr>
                                </b-col>
                              </b-row>
                            </validation-observer>
                          </template>
                        </b-form>
                      </div>
                    </b-card>
                  </b-col>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <b-modal
      id="delete-training"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        confirm-btn="Yes"
        cancel-btn="No"
        :title="$t('Are you sure to delete training/ program?')"
        @cancel="hideAlertModal('training')"
        @confirm="confirmDelete('training')"
      />
    </b-modal>
    <b-modal
      id="delete-self-training"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        confirm-btn="Yes"
        cancel-btn="No"
        :title="$t('Are you sure to delete self learning?')"
        @cancel="hideAlertModal('self')"
        @confirm="confirmDelete('self')"
      />
    </b-modal>
    <b-modal
      id="delete-external"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        confirm-btn="Yes"
        cancel-btn="No"
        :title="$t('Are you sure to delete external contribution?')"
        @cancel="hideAlertModal('external')"
        @confirm="confirmDelete('external')"
      />
    </b-modal>
    <b-modal
      id="delete-internal"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        confirm-btn="Yes"
        cancel-btn="No"
        :title="$t('Are you sure to delete internal/industry involvement?')"
        @cancel="hideAlertModal('internal')"
        @confirm="confirmDelete('internal')"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BButton,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BForm,
  BFormRating,
  VBModal,
  BModal,
} from "bootstrap-vue"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import axios from "axios"
import AlertModal from "@/pages/alert-modal.vue"
import SAJToast from "@/component/saj-toastification.vue"
import { mapGetters } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BFormSelect,
    flatPickr,
    BForm,
    BFormFile,
    BFormRating,
    AlertModal,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contributions: [],
      isSaveTraining: false,
      isSaveContr: false,
      isSave: false,
      id: this.$route.params.id,
      status: this.$route.params.status,
      required,
      showExternal: false,
      showInternal: false,
      tambah: "",
      value: "",
      self_score: "",
      weightages: {},
      training: [],
      trainingList: [],
      existitems2: [],
      contributionList: [],
      selfLearnTrainingList: [],
      items: [
        {
          program_name: "",
          start_date: "",
          end_date: "",
          tempStart_date: "",
          tempEnd_date: "",
          hour: 0,
          self_score: "",
          self_learn: 0,
          fileToUpload: [],

          // id: 1,
          // prevHeight: 0,
        },
      ],
      items2: [
        {
          date: "",
          tempDate: "",
          position: null,
          self_score: "",
          involvement: "",
          type: 1,
          fileToUpload: [],
        },
      ],
      items3: [
        {
          date2: "",
          tempDate2: "",
          position2: null,
          self_score2: "",
          involvement2: "",
          type: 0,
          fileToUpload: [],
        },
      ],
      items4: [
        {
          program_name: "",
          start_date: "",
          end_date: "",
          hour: 0,
          self_score: "",
          self_learn: 1,
          fileToUpload: [],
        },
      ],

      jawatanoptions1: [
        { value: 1, text: `${this.$t("Normal Member (1 mark)")}`, id: 1 },
        { value: 2, text: `${this.$t("Committee Member (2 marks)")}`, id: 2 },
        { value: 3, text: `${this.$t("Exco (3 marks)")}`, id: 3 },
        { value: 4, text: `${this.$t("Treasurer/District (4 marks)")}`, id: 4 },
        { value: 5, text: `${this.$t("Secretary/State (5 marks)")}`, id: 5 },
        {
          value: 6,
          text: `${this.$t("Chairman/President/National (6 marks)")}`,
          id: 6,
        },
      ],
      jawatanoptions2: [
        { value: 1, text: `${this.$t("Normal Member (1 mark)")}`, id: 1 },
        { value: 2, text: `${this.$t("Committee Member (2 marks)")}`, id: 2 },
        { value: 3, text: `${this.$t("Exco (3 marks)")}`, id: 3 },
        { value: 4, text: `${this.$t("Treasurer/District (4 marks)")}`, id: 4 },
        { value: 5, text: `${this.$t("Secretary/State (5 marks)")}`, id: 5 },
        {
          value: 6,
          text: `${this.$t("Chairman/President/National (6 marks)")}`,
          id: 6,
        },
      ],
      total: this.calculateTotal,
      isModalVisible: false,
      deleteId: null,
      deleteId2: null,
      deleteId3: null,
      deleteId4: null,
      isEditCont: false,
      isEditTrain: false,
      rating: [],
      approved_at: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === "en"
    },
    ...mapGetters(["roles", "userDetails"]),
  },
  watch: {
    refresh: {
      handler(val) {
        if (val) {
          this.getTrainings()
          this.getContributions()
        }
      },
    },
    // eslint-disable-next-line func-names
    "$i18n.locale": function () {
      this.jawatanoptions1 = [
        { value: 1, text: `${this.$t("Normal Member (1 mark)")}`, id: 1 },
        { value: 2, text: `${this.$t("Committee Member (2 marks)")}`, id: 2 },
        { value: 3, text: `${this.$t("Exco (3 marks)")}`, id: 3 },
        { value: 4, text: `${this.$t("Treasurer/District (4 marks)")}`, id: 4 },
        { value: 5, text: `${this.$t("Secretary/State (5 marks)")}`, id: 5 },
        {
          value: 6,
          text: `${this.$t("Chairman/President/National (6 marks)")}`,
          id: 6,
        },
      ]
      // jawatanselected2: "a",
      this.jawatanoptions2 = [
        { value: 1, text: `${this.$t("Normal Member (1 mark)")}`, id: 1 },
        { value: 2, text: `${this.$t("Committee Member (2 marks)")}`, id: 2 },
        { value: 3, text: `${this.$t("Exco (3 marks)")}`, id: 3 },
        { value: 4, text: `${this.$t("Treasurer/District (4 marks)")}`, id: 4 },
        { value: 5, text: `${this.$t("Secretary/State (5 marks)")}`, id: 5 },
        {
          value: 6,
          text: `${this.$t("Chairman/President/National (6 marks)")}`,
          id: 6,
        },
      ]
    },
  },
  mounted() {
    this.getScoreRating()
    this.getTrainings()
    this.getContributions()
    this.getDataIndustry()
    this.weightage()
    // console.log('id', this.id)
  },
  methods: {
    getScoreRating() {
      this.$axios
        .get(`${this.$baseUrl}/rating/getAll?option=part3`)
        .then(response => {
          this.rating = response.data.data
        })
    },
    onFileChange(e) {
      if (e.target.files.length !== 0) {
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120) {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t("File must less than 5 MB")}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: "#e80202",
              },
            },
            {
              position: "top-right",
              type: "info",
            },
          )
        }
      }
    },
    setStartDate(index) {
      this.items[index].start_date = this.items[index].tempStart_date
      // console.log('masuks')
    },
    setEndDate(index) {
      this.items[index].end_date = this.items[index].tempEnd_date
      // console.log('masuk end date')
    },
    setDate1(index) {
      this.items2[index].date = this.items2[index].tempDate
      // console.log('masuk date 1')
    },
    setDate2(index) {
      this.items3[index].date2 = this.items3[index].tempDate2
      // console.log('masuk date 2')
    },
    showAlertModal(type, id) {
      switch (type) {
        case "training":
          this.$bvModal.show("delete-training")
          this.deleteId = id
          break
        case "self":
          this.$bvModal.show("delete-self-training")
          this.deleteId4 = id
          break
        case "external":
          this.$bvModal.show("delete-external")
          this.deleteId2 = id
          break
        default:
          this.$bvModal.show("delete-internal")
          this.deleteId3 = id
      }
    },

    hideAlertModal(type) {
      switch (type) {
        case "training":
          this.$bvModal.hide("delete-training")
          break
        case "self":
          this.$bvModal.hide("delete-self-training")
          break
        case "external":
          this.$bvModal.hide("delete-external")
          break
        case "internal":
          this.$bvModal.hide("delete-internal")
          break
        default:
          this.$bvModal.hide("delete-training")
          this.$bvModal.hide("delete-self-training")
          this.$bvModal.hide("delete-external")
          this.$bvModal.hide("delete-internal")
      }
    },
    confirmDelete(type) {
      switch (type) {
        case "training":
          this.deleteTraining(this.deleteId)
          break
        case "self":
          this.deleteTraining(this.deleteId4)
          break
        case "external":
          this.deleteContribution(this.deleteId2)
          break
        default:
          this.deleteContribution(this.deleteId3)
      }
    },
    getUserSelfScore(data) {
      let score = "--"
      this.jawatanoptions1.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },
    getUserSelfScore2(data) {
      let score = "--"
      this.jawatanoptions2.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },

    repeateAgain() {
      this.items.push({
        program_name: "",
        start_date: "",
        end_date: "",
        hour: 0,
        self_score: "",
        tempStart_date: "",
        tempEnd_date: "",
        fileToUpload: [],
      })
    },
    // Form Repeater 2 ------------------------------------------------
    repeateAgain2() {
      this.items2.push({
        date: "",
        tempDate: "",
        position: "",
        self_score: "",
        involvement: "",
        fileToUpload: [],
      })
    },
    // Form Repeater 3 ------------------------------------------------
    repeateAgain3() {
      this.items3.push({
        date2: "",
        tempDate2: "",
        position2: "",
        self_score2: "",
        involvement2: "",
        fileToUpload: [],
      })
    },
    // Form Repeater 4 ------------------------------------------------
    repeateAgain4() {
      this.items4.push({
        program_name: "",
        start_date: "",
        end_date: "",
        hour: "",
        self_score: "",
        summary_comment: "",
        fileToUpload: [],
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    removeItem2(index) {
      this.items2.splice(index, 1)
    },
    removeItem3(index) {
      this.items3.splice(index, 1)
    },
    removeItem4(index) {
      this.items4.splice(index, 1)
    },
    toggleInput3() {
      this.disableTambah = !this.disableTambah
    },
    noContribution() {
      const data = new FormData()

      const constributionStat = this.contributions.hasContribution ? 0 : 1

      data.append("contribution", constributionStat)
      data.append("employee_id", this.userDetails.id)

      this.$axios
        .post(`${this.$baseUrl}/button/noContribution`, data)
        .then(() => {
          // console.log(res)
          this.getContributions()
        })
    },
    addTraining() {
      if (this.trainingList.length == 0) {
        const data = new FormData()

        const trainingStat = this.training.hasTraining ? 0 : 1

        data.append("training", trainingStat)
        data.append("employee_id", this.userDetails.id)

        this.$axios
          .post(`${this.$baseUrl}/button/noTraining`, data)
          .then(() => {
            // console.log(res)
            this.getTrainings()
          })
      }
    },
    getTrainings() {
      const data = new FormData()
      this.$axios
        .get(
          `${this.$baseUrl}/myperformances/training_development?cycle_id=${this.id}`,
          data,
        )
        .then(response => {
          console.log('response traininggggg', response)
          const { data } = response.data

          this.approved_at = response.data.data.submit_at

          if (data.training_development && data.training_development.length > 0) {
 this.selfLearnTrainingList = data.training_development
            .filter(item => item.self_learn != 0)
            .map(item => ({
              id4: item.id,
              program_name: item.program_name,
              start_date: item.start_date,
              end_date: item.end_date,
              hour: item.hour,
              summary_comment: item.summary_comment,
              self_score: item.self_score,
              manager_score: item.manager_score,
              file: item.file,
              fileToUpload: null,
            }))

          console.log('training dev', data)
          this.trainingList = data.training_development.map(item => ({
            id: item.id,
            program_name: item.program_name,
            start_date: item.start_date,
            end_date: item.end_date,
            hour: item.hour,
            self_score: item.self_score,
            manager_score: item.manager_score,
            file: item.file,
            fileToUpload: null,
          }))
          } else {
            this.selfLearnTrainingList = [];
            this.trainingList = [];
          }
         

          this.training = data
        })
    },
    getContributions() {
      const data = new FormData()
      axios
        .get(
          `${this.$baseUrl}/myperformances/contribution?cycle_id=${this.id}`,
          data,
        )
        .then(response => {
          console.log('asas', response.data.data)
          const { data } = response.data
          this.contributions = response.data.data

          this.contributionList = data.contribution.map(item => ({
            id3: item.id,
            involvement: item.involvement,
            date: item.date,
            position: item.position,
            self_score: item.self_score,
            file: item.file,
            fileToUpload: null,
          }))
        })
    },
    getDataIndustry() {
      const data = new FormData()
      axios
        .get(
          `${this.$baseUrl}/myperformances/contribution?cycle_id=${this.id}`,
          data,
        )
        .then(response => {
          for (let i = 0; i < response.data.data.contribution.length; i += 1) {
            // console.log('type', response.data.data.contribution[i].type)
            this.contributionList.push({
              id2: response.data.data.contribution[i].id,
              involvement: response.data.data.contribution[i].involvement,
              date: response.data.data.contribution[i].date,
              position: response.data.data.contribution[i].position,
              self_score: response.data.data.contribution[i].self_score,
              file: response.data.data.contribution[i].file,
              fileToUpload: null,
            })
          }
        })
    },
    weightage() {
      const params = new URLSearchParams()
      params.append("subsidiary_id", this.userDetails.business_unit)
      params.append("role_id", this.userDetails.grade_set_id)

      this.$axios
        .get(`${this.$baseUrl}/weightage/get_role_percentage`, { params })
        .then(response => {
          const { data } = response.data

          this.weightages = data
        })
    },
    validateForm() {
      const validatePart3ALength = this.$refs.part3ValidationNew.length
      const isAllTrue = []
      this.isSaveTraining = true
      if (this.trainingList.length !== 0) {
        const validatePart3LengthUpdate = this.$refs.part3ValidationUpdate.length
        this.$refs.part3ValidationUpdate.forEach((item, index) => {
          this.$refs.part3ValidationUpdate[index].validate().then(success => {
            isAllTrue.push(success)
            if (!success) {
              this.isSaveTraining = false
            }
            if (index === validatePart3LengthUpdate - 1) {
              const result = isAllTrue.every(e => e === true)
              if (result) {
                this.$refs.part3ValidationNew.forEach((item2, index2) => {
                  this.$refs.part3ValidationNew[index2]
                    .validate()
                    .then(success1 => {
                      isAllTrue.push(success1)
                      if (!success1) {
                        this.isSaveTraining = false
                      }
                      if (index2 === validatePart3ALength - 1) {
                        const result1 = isAllTrue.every(e => e === true)
                        if (result1) {
                          this.updateTraining()
                          this.isEditTrain = !this.isEditTrain
                        }
                      }
                    })
                })
              }
            }
          })
        })
      } else {
        this.$refs.part3ValidationNew.forEach((item, index) => {
          this.$refs.part3ValidationNew[index].validate().then(success => {
            isAllTrue.push(success)
            if (!success) {
              this.isSaveTraining = false
            }
            if (index === validatePart3ALength - 1) {
              const result = isAllTrue.every(e => e === true)
              if (result) {
                this.updateTraining()
                this.isEditTrain = !this.isEditTrain
              }
            }
          })
        })
      }
    },

    validateFormPartB() {
      const validatePart3Length = this.$refs.part3BValidationNew.length
      const isAllTrue = []
      this.isSaveContr = true
      if (this.existitems2.length !== 0 || this.contributionList.length !== 0) {
        const validatePart3BLengthUpdate = this.$refs.part3BValidationUpdate.length
        this.$refs.part3BValidationUpdate.forEach((item, index) => {
          this.$refs.part3BValidationUpdate[index]
            .validate()
            .then(success => {
              isAllTrue.push(success)
              if (!success) {
                this.isSaveContr = false
              }
              if (index === validatePart3BLengthUpdate - 1) {
                const result = isAllTrue.every(e => e === true)
                if (result) {
                  this.$refs.part3BValidationNew.forEach((item2, index2) => {
                    this.$refs.part3BValidationNew[index2]
                      .validate()
                      .then(success1 => {
                        isAllTrue.push(success1)
                        if (!success1) {
                          this.isSaveContr = false
                        }
                        if (index2 === validatePart3Length - 1) {
                          const result1 = isAllTrue.every(e => e === true)
                          if (result1) {
                            this.updateContribution()
                            this.isEditCont = !this.isEditCont
                          }
                        }
                      })
                  })
                }
              }
            })
        })
      } else {
        this.$refs.part3BValidationNew.forEach((item, index) => {
          this.$refs.part3BValidationNew[index].validate().then(success => {
            isAllTrue.push(success)
            if (!success) {
              this.isSaveContr = false
            }
            if (index === validatePart3Length - 1) {
              const result = isAllTrue.every(e => e === true)
              if (result) {
                this.updateContribution()
                this.toggleInput2()
                this.isEditCont = !this.isEditCont
              }
            }
          })
        })
      }
    },
    updateTraining() {
      const data = new FormData()
      let i = 0
      for (i; i < this.items.length; i += 1) {
        if (this.items[i].program_name !== "") {
          // const date = this.items2[i].date.split("-").reverse().join("-")
          data.append(`program_name[]`, this.items[i].program_name)
          data.append(`start_date[]`, this.items[i].start_date)
          data.append(`end_date[]`, this.items[i].end_date)
          data.append(`hour[]`, this.items[i].hour)
          data.append(`self_score[]`, this.items[i].self_score)
          // data.append(`file[]`, this.items[i].fileToUpload)
          data.append(`self_learn[]`, 0)
          if (this.items[i].fileToUpload.length !== 0) {
            data.append(`file[]`, this.items[i].fileToUpload)
          }
        }
      }
      let i2 = 0
      for (i2; i2 < this.trainingList.length; i2 += 1) {
        // const date = this.items2[i].date.split("-").reverse().join("-")
        const idd = this.trainingList[i2].id

        data.append(`training_development_id[${i2}]`, idd)
        data.append(
          `update_program_name[${idd}]`,
          this.trainingList[i2].program_name,
        )
        data.append(
          `update_start_date[${idd}]`,
          this.trainingList[i2].start_date,
        )
        data.append(`update_end_date[${idd}]`, this.trainingList[i2].end_date)
        data.append(`update_hour[${idd}]`, this.trainingList[i2].hour)
        data.append(
          `update_self_score[${idd}]`,
          this.trainingList[i2].self_score,
        )
        if (this.trainingList[i2].fileToUpload !== null) {
          data.append(
            `update_file[${idd}]`,
            this.trainingList[i2].fileToUpload,
          )
        }
        data.append(`self_learn[${idd}]`, 0)
      }
      let j = i
      let k = 0
      for (j; j < this.items4.length + i; j += 1) {
        if (this.items4[k].program_name !== "") {
          // const date2 = this.items3[k].date2.split("-").reverse().join("-")
          data.append(`program_name[${j}]`, this.items4[k].program_name)
          data.append(`start_date[${j}]`, this.items4[k].start_date)
          data.append(`end_date[${j}]`, this.items4[k].end_date)
          data.append(`hour[${j}]`, this.items4[k].hour)
          if (this.items4[k].summary_comment !== undefined) {
            data.append(
              `summary_comment[${j}]`,
              this.items4[k].summary_comment,
            )
          }
          data.append(`self_score[${j}]`, this.items4[k].self_score)
          // data.append(`file[${j}]`, this.items4[k].fileToUpload)
          if (this.items4[k].fileToUpload.length !== 0) {
            data.append(`file[${j}]`, this.items4[k].fileToUpload)
          }
          data.append(`self_learn[${j}]`, 1)
          k += 1
        }
      }
      let j2 = i2
      k = 0
      for (j2; j2 < this.selfLearnTrainingList.length + i2; j2 += 1) {
        // const date2 = this.items3[k].date2.split("-").reverse().join("-")
        const iddd = this.selfLearnTrainingList[k].id4
        data.append(`training_development_id[${j2}]`, iddd)
        data.append(
          `update_program_name[${iddd}]`,
          this.selfLearnTrainingList[k].program_name,
        )
        data.append(
          `update_start_date[${iddd}]`,
          this.selfLearnTrainingList[k].start_date,
        )
        data.append(
          `update_end_date[${iddd}]`,
          this.selfLearnTrainingList[k].end_date,
        )
        data.append(`update_hour[${iddd}]`, this.selfLearnTrainingList[k].hour)
        data.append(
          `update_summary_comment[${iddd}]`,
          this.selfLearnTrainingList[k].summary_comment,
        )
        data.append(
          `update_self_score[${iddd}]`,
          this.selfLearnTrainingList[k].self_score,
        )
        if (this.selfLearnTrainingList[k].fileToUpload !== null) {
          data.append(
            `update_file[${iddd}]`,
            this.selfLearnTrainingList[k].fileToUpload,
          )
        }
        data.append(`self_learn[${iddd}]`, 1)
        k += 1
      }

      this.items.length = 1
      this.items = this.items.map(() => ({
        program_name: "",
        start_date: "",
        end_date: "",
        tempStart_date: "",
        tempEnd_date: "",
        hour: 0,
        self_score: "",
        fileToUpload: [],
      }))

      this.items4.length = 1
      this.items4 = this.items4.map(() => ({
        program_name: "",
        start_date: "",
        end_date: "",
        hour: 0,
        self_score: "",
        fileToUpload: [],
      }))
      this.$axios
        .post(`${this.$baseUrl}/myperformances/training_development`, data)
        .then(() => {
          if (
            this.trainingList.length !== 0
            || this.selfLearnTrainingList.length !== 0
          ) {
            this.trainingList = []
            this.selfLearnTrainingList = []
            this.getTrainings()
          } else {
            this.getTrainings()
          }
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t("Training/ program successfully saved")}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: "info",
              },
            )
            this.isSaveTraining = false
          }, 1000)
        })
        .catch(() => {
          // console.log('error: ', error.response)
          this.getTrainings()
        })
    },
    updateContribution() {
      const data = new FormData()
      let i = 0
      for (i; i < this.items2.length; i += 1) {
        if (this.items2[i].involvement !== "") {
          // const date = this.items2[i].date.split("-").reverse().join("-")
          data.append(`involvement[]`, this.items2[i].involvement)
          data.append(`date[]`, this.items2[i].date)
          data.append(`position[]`, this.items2[i].position)
          data.append(`self_score[]`, this.items2[i].self_score)
          data.append(`type[]`, 1)
          // data.append(`file[]`, this.items2[i].fileToUpload)
          if (this.items2[i].fileToUpload.length !== 0) {
            data.append(`file[]`, this.items2[i].fileToUpload)
          }
        }
      }
      let i2 = 0
      for (i2; i2 < this.existitems2.length; i2 += 1) {
        // const date = this.items2[i].date.split("-").reverse().join("-")
        const idd = this.existitems2[i2].id2

        data.append(`contribution_id[${i2}]`, idd)
        data.append(
          `update_involvement[${idd}]`,
          this.existitems2[i2].involvement,
        )
        data.append(`update_date[${idd}]`, this.existitems2[i2].date)
        data.append(`update_position[${idd}]`, this.existitems2[i2].position)
        data.append(
          `update_self_score[${idd}]`,
          this.existitems2[i2].self_score,
        )
        data.append(`type[${idd}]`, 1)
        data.append(
          `update_file[${idd}]`,
          this.existitems2[i2].fileToUpload
            ? this.existitems2[i2].fileToUpload
            : null,
        )
      }
      let j = i
      let k = 0
      for (j; j < this.items3.length + i; j += 1) {
        if (this.items3[k].involvement2 !== "") {
          // console.log('masuk ke', this.items3[k].involvement2)
          // const date2 = this.items3[k].date2.split("-").reverse().join("-")
          data.append(`involvement[${j}]`, this.items3[k].involvement2)
          data.append(`date[${j}]`, this.items3[k].date2)
          data.append(`position[${j}]`, this.items3[k].position2)
          data.append(`self_score[${j}]`, this.items3[k].self_score2)
          // data.append(`type[${j}]`, 0)
          // data.append(`file[${j}]`, this.items3[k].fileToUpload)
          if (this.items3[k].fileToUpload.length !== 0) {
            data.append(`file[${j}]`, this.items3[k].fileToUpload)
          }
          k += 1
        }
      }
      let j2 = i2
      k = 0
      for (j2; j2 < this.contributionList.length + i2; j2 += 1) {
        const iddd = this.contributionList[k].id2
        data.append(`contribution_id[${j}]`, iddd)
        data.append(
          `update_involvement[${iddd}]`,
          this.contributionList[k].involvement,
        )
        data.append(`update_date[${iddd}]`, this.contributionList[k].date)
        data.append(
          `update_position[${iddd}]`,
          this.contributionList[k].position,
        )
        data.append(
          `update_self_score[${iddd}]`,
          this.contributionList[k].self_score,
        )
        // data.append(`type[${iddd}]`, 0)
        data.append(
          `update_file[${iddd}]`,
          this.contributionList[k].fileToUpload
            ? this.contributionList[k].fileToUpload
            : null,
        )
        k += 1
        j += 1
      }
      this.items2.length = 1
      this.items2 = this.items2.map(() => ({
        date: "",
        tempDate: "",
        position: null,
        self_score: "",
        involvement: "",
        type: 1,
        fileToUpload: [],
      }))
      this.items3.length = 1
      this.items3 = this.items3.map(() => ({
        date2: "",
        tempDate2: "",
        position2: "",
        self_score2: "",
        involvement2: "",
        type: 0,
        fileToUpload: [],
      }))

      this.$axios
      // .post(`${this.$baseUrl}/myperformances/contribution`, data) --> for internal and external involvement

        .post(`${this.$baseUrl}/myperformances/contribution`, data)
        .then(() => {
          if (
            this.existitems2.length !== 0
            || this.contributionList.length !== 0
          ) {
            this.existitems2 = []
            this.contributionList = []
            this.getDataIndustry()
          } else {
            this.getDataIndustry()
          }
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t("Contribution successfully saved")}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: "info",
              },
            )
            this.isSaveContr = false
          }, 1000)
        })
        .catch(() => {
          this.getDataIndustry()
        })
    },
    deleteTraining(val) {
      const data = new FormData()

      data.append(`training_id`, val)

      this.$axios
        .post(
          `${this.$baseUrl}/myperformances/delete_training_development`,
          data,
        )
        .then(() => {
          if (
            this.trainingList.length !== 0
            || this.selfLearnTrainingList.length !== 0
          ) {
            this.trainingList = []
            this.selfLearnTrainingList = []
            this.getTrainings()
          } else {
            this.getTrainings()
          }
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t("Training/ program successfully deleted")}.`,
                icon: "Trash2Icon",
                iconBg: "#e80202",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: "error",
            },
          )

          this.hideAlertModal()
        })
    },
    deleteContribution(val) {
      const data = new FormData()

      data.append(`contribution_id`, val)

      this.$axios
        .post(`${this.$baseUrl}/myperformances/delete_contribution`, data)
        .then(() => {
          if (
            this.existitems2.length !== 0
            || this.contributionList.length !== 0
          ) {
            this.existitems2 = []
            this.contributionList = []
            this.getDataIndustry()
          } else {
            this.getDataIndustry()
          }
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t("Contribution successfully deleted")}.`,
                icon: "Trash2Icon",
                iconBg: "#e80202",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: "error",
            },
          )
          this.hideAlertModal()
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disable-flat-pickr {
  background-color: gray;
}
.star {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
}
a {
  color: black;
}
a:hover {
  color: red;
}
.addItem {
  background-color: #efefef !important;
  border-radius: 0.357rem;
}
.addItem2 {
  background-color: white !important;
  border-radius: 0.357rem;
}
</style>
<style>
input[type="file"] {
  text-align: left;
}
</style>
