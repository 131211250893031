<template>
  <div>
    <b-modal
      id="delete-image"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click submit to remove profile picture.')"
        @cancel="hideAlertModal"
        @confirm="deletePicture()"
      />
    </b-modal>
    <b-card
      class="m-0 mb-2"
      style="
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
        border-radius: 20px;
        height: auto;
      "
    >
      <b-container class="bv-example-row">
        <b-row>
          <!-- column 1 -->
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <b-container class="bv-example-row">
              <!-- Editor Tools -->
              <b-row class="d-flex justify-content-end">
                <feather-icon
                  v-if="isNewLogo && !errorFormat && !errorSize"
                  v-b-tooltip.hover.bottom="$t('Save')"
                  class="mr-1"
                  icon="SaveIcon"
                  size="16"
                  style="height: 28px"
                  @click="savePicture()"
                />
                <feather-icon
                  v-if="isEdit && profile.image !== null"
                  v-b-tooltip.hover.bottom="$t('Delete')"
                  class="mr-1"
                  icon="Trash2Icon"
                  size="16"
                  style="height: 28px"
                  @click="showAlertModal"
                />
                <feather-icon
                  v-if="isEdit"
                  v-b-tooltip.hover.bottom="$t('Cancel')"
                  class="mr-1"
                  icon="XIcon"
                  size="16"
                  style="height: 28px"
                  @click="
                    (isEdit = !isEdit),
                    (isNewLogo = false),
                    (errorFormat = false),
                    (newLogoTemp = null)
                  "
                />
              </b-row>
              <!-- Image Preview and select -->

              <b-row>
                <b-col
                  class="d-flex justify-content-center"
                  style=""
                >
                  <div
                    class="d-flex justify-content-center align-items-center"
                    style="position: relative; width: 130px; height: 130px"
                  >
                    <div
                      v-if="isEdit"
                      style="
                        position: absolute;
                        height: 7em;
                        width: 7em;
                        background-color: #f0ffff82;
                        border-radius: 50%;
                      "
                      @click="chooseFiles()"
                    >
                      <feather-icon
                        class=""
                        icon="CameraIcon"
                        size="16"
                        style="
                          position: absolute;
                          top: 37%;
                          right: 34%;
                          height: 30px;
                          width: 30px;
                        "
                      />
                    </div>
                    <feather-icon
                      v-if="!isEdit && !viewOnly"
                      v-b-tooltip.hover.bottom="$t('Edit')"
                      class=""
                      size="9"
                      icon="EditIcon"
                      style="
                        position: absolute;
                        bottom: 18px;
                        right: 18px;
                        border-radius: 50%;
                        background-color: #d0d2d6;
                        height: 30px;
                        width: 30px;
                        padding: 5px;
                      "
                      @click="isEdit = !isEdit"
                    />
                    <img
                      v-if="profile.image === null && !isNewLogo"
                      class="imageProfile"
                      :style="
                        viewOnly
                          ? ''
                          : `border-radius: 50%; border: solid 3px #d0d2d6`
                      "
                      :src="require(`@/assets/images/user.png`)"
                    >
                    <img
                      v-else
                      class="imageProfile"
                      :style="
                        viewOnly
                          ? ''
                          : `border-radius: 50%; border: solid 3px #d0d2d6`
                      "
                      :src="isNewLogo ? newLogoTemp : `${profile.image}`"
                    >
                    <small
                      v-if="errorFormat"
                      style="color: red; position: absolute; bottom: -3vh;"
                    >{{
                      $t("Hanya format jpg, jpeg dan png dibenarkan")
                    }}</small>
                    <small
                      v-if="errorSize"
                      style="color: red; position: absolute; bottom: -3vh;"
                    >{{
                      $t("Picture size must be less that 5 MB")
                    }}</small>
                  </div>
                </b-col>
              </b-row>
              <!-- Select new profile picture -->
              <b-row>
                <b-col
                  class=""
                  style="
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                  "
                >
                  <input
                    id="fileUpload"
                    ref="companyPicture"
                    type="file"
                    hidden
                    @change="onFileChange"
                  >
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <!-- column 2 -->
          <b-col cols="9">
            <b-row class="saj-title m-0 mb-1 mt-1 p-0">
              <p
                class="ellipsisProfile"
                style="font-weight: 600;"
              >
                {{ $t(name) }}
              </p>
            </b-row>

            <b-row>
              <b-col md="4">
                <p class="saj-text d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Employee Id')"
                    class="mr-1"
                    icon="ClipboardIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(employee_number) }} </span>
                </p>
              </b-col>

              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Email')"
                    class="mr-1"
                    icon="MailIcon"
                    size="22"
                    stroke="black"
                  />

                  <span class="ellipsisProfile">
                    {{ $t(email) }}
                  </span>
                </p>
              </b-col>

              <b-col md="4">
                <div class="saj-text  d-flex">
                  <img
                    v-b-tooltip.hover.bottom="$t('Department')"
                    class="mr-1"
                    src="@/assets/images/icons/department.png"
                    width="23px"
                    height="23px"
                  >
                  <span class="ellipsisProfile">
                    {{ $t(department) }}
                  </span>
                </div>
              </b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Position')"
                    class="mr-1"
                    icon="UserIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile">{{ $t(position) }} </span>
                </p>
              </b-col>

              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Supervisor')"
                    class="mr-1"
                    icon="UsersIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(supervisor) }} </span>
                </p>
              </b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Phone Number')"
                    class="mr-1"
                    icon="PhoneIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(mobile_number) }} </span>
                </p></b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Hire Date')"
                    class="mr-1"
                    icon="CalendarIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(DateChanged(hire_date)) }} </span>
                </p>
                <p
                  class="saj-text  d-flex"
                /></b-col>
            </b-row> </b-col></b-row></b-container>
    </b-card>
  </div>
</template>
<style scoped>
.hiddenFileInput > input {
  height: 100%;
  width: 90;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInput {
  /*display: flex;
  justify-content: center;
  padding-bottom: 30px; */
  display: inline-block;
  overflow: hidden;
  height: 60px;
  width: 150px;
  background: no-repeat;
  background-image: url("~@/assets/images/button/muatnaik button.png");
}
</style>

<script>
import {
  BRow,
  BCol,
  BCard,
  BContainer,
  VBTooltip,
  BModal,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import AlertModal from "@/pages/alert-modal.vue"
import SAJToast from "@/component/saj-toastification.vue"
import moment from "moment"

// eslint-disable-next-line import/no-unresolved
const states = require("@/db/states.json")

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    // BImg,
    AlertModal,
    BModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showAlert: false,
      errorFormat: false,
      errorSize: false,
      newProfilePic: null,
      isEdit: false,
      file: null,
      name: "",
      role: "Executive",
      email: "",
      position: "",
      mobile_number: "",
      employee_number: "",
      department: "",
      branch: "",
      district: "",
      supervisor: "",
      supervisor_id: "",
      hire_date: "",
      stateList: states,
      department_id: "",

      profile: {
        image: "",
        full_name: "",
        mobile_number: "",
        ic: "",
        dob: "",
        gender: "",
        religion: "",
        race: null,
        marital_status: "",
        nationality: "",
        address: "",
        postal_code: 0,
        city: "",
        country: "",
        position: "",
        grade: "",
        grade_set: "",
        employee_number: "",
        hire_date: "",
        business_unit: "",
        role: "",
        state: null,
      },
      isSetup: false,
      isNewLogo: false,
      newLogo: "",
      newLogoTemp: null,
    }
  },
  watch: {
    latitude() {
      this.company.latitude = this.currLatitude
    },
    longitude() {
      this.company.longitude = this.currLongitude
    },
    isNewLogo() {
    },
  },
  mounted() {
    this.getDetails()
    this.getRoles()
    this.getProfileImage()
  },
  methods: {
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    showAlertModal() {
      this.$bvModal.show("delete-image")
    },
    hideAlertModal() {
      this.$bvModal.hide("delete-image")
    },
    chooseFiles() {
      document.getElementById("fileUpload").click()
    },
    getProfileImage() {
      this.$axios
        .get(`${this.$baseUrl}/profile_picture/getProfilePicture`)
        .then(response => {
          const val = response.data.data.file

          if (val === null) {
            this.profile.image = null
          } else {
            this.profile.image = val.url
          }
        })
    },
    deletePicture() {
      this.$axios
        .post(`${this.$baseUrl}/profile_picture/deleteProfilePicture`)
        .then(() => {
          this.getProfileImage()
          this.isEdit = false
          this.isNewLogo = false
          this.profile.image = null
          this.hideAlertModal()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Profile picture successfully deleted')}.`,
                icon: "Trash2Icon",
                iconBg: "#e80202",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    savePicture() {
      const data = new FormData()
      data.append("file", this.newProfilePic)
      this.$axios
        .post(`${this.$baseUrl}/profile_picture/editProfilePicture`, data)
        .then(() => {
          this.getProfileImage()
          this.isEdit = false
          this.isNewLogo = false
          window.parent.location.reload()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Profile picture successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    // repeateAgain() {
    //   this.items.push({
    //     qualification: "",
    //     institute: "",
    //     field_of_study: "",
    //     year_of_graduate: 0,
    //     file: {},
    //   })
    // },
    getRoles() {
      const data = new FormData()
      this.$axios
        .get(`${this.$baseUrl}/roles/getAllRoles`, data)
        .then(response => {
          this.role = response.data.data[2].name
        })
    },
    getDetails() {
      this.$axios
        .get(`${this.$baseUrl}/users/current_user`)
        .then(response => {
          if (response.data.success === true) {
            const profileData = response.data.data
            this.name = profileData.name !== null ? profileData.name : "-"
            this.email = profileData.email !== null ? profileData.email : "-"
            this.position = profileData.employee.position !== null
              ? profileData.employee.position
              : "-"
            this.mobile_number = profileData.employee.mobile_number !== null
              ? profileData.employee.mobile_number
              : "-"
            this.employee_number = profileData.employee.employee_number !== null
              ? profileData.employee.employee_number
              : "-"
            this.department = profileData.department !== null ? profileData.department : "-"
            this.supervisor = profileData.supervisor !== null ? profileData.supervisor : "-"
            this.hire_date = profileData.employee.hire_date !== null
              ? profileData.employee.hire_date
              : "-"
          }
        })
    },
    onFileChange(e) {
      if (e.target.files.length !== 0){
        const file = e.target.files[0]
        this.newProfilePic = file
        this.isNewLogo = true
        this.testData = true
        if (
          this.newProfilePic.type !== "image/jpeg"
        && this.newProfilePic.type !== "image/jpg"
        && this.newProfilePic.type !== "image/png"
        ) {
          this.errorFormat = true
        } else {
          this.errorFormat = false
        }
        this.newLogoTemp = URL.createObjectURL(file)

        if (file.size > 5242880) {
          // this.$toast(
          //   {
          //     component: SAJToast,
          //     props: {
          //       title: `${this.$t('Picture size must be less that 5 MB')}.`,
          //       icon: "AlertTriangleIcon",
          //       iconColor: 'white',
          //       iconBg: '#FF000F',
          //       titleColor: "#000",
          //     },
          //   },
          //   {
          //     position: "top-right",
          //     type: 'info',
          //   },
          // )

          this.errorSize = true
        } else {
          this.errorSize = false
        }

        // console.log('file', file)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.ellipsisProfile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
