<template>
  <div class="">
    <div class="d-flex flex-column">
      <div>
        <profile
          v-if="main"
          :view-only="true"
        />

      </div>
      <!-- Tab -->
      <b-tabs
        v-model="tabNo"
        card
      >
        <b-tab
          class="saj-title p-0 "
          :title="$t('Part') + ' ' + 1"
          @click="!main ? $router.push({ name: 'my-performance', params: { id, tabIdx: 0 } }) : ''"
        >
          <template v-if="main">

            <div v-if="roles.isExecutive || roles.isTopManagement">
              <!-- <KRAModule class="mt-2" /> -->
              <summaryKRA
                :is-self="true"
                class="mt-3"
              />
            </div>
          </template>

          <template v-if="main">

            <div v-if="roles.isUser">
              <div v-if="setting === 0">
                <SummaryJDNonExec class="mt-3" />
              </div>
              <div v-else>
                <SummaryKRANonExec class="mt-3" />
              </div>

            </div>
          </template>

        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Part') + ' ' + 2"
          @click="!main ? $router.push({ name: 'my-performance', params: { id, tabIdx: 1 } }) : ''"
        >
          <performance-part-2
            :refresh="refreshData"
          />
        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Part') + ' ' + 3"
          @click="!main ? $router.push({ name: 'my-performance', params: { id, tabIdx: 2 } }) : ''"
        >
          <performance-part-3
            :refresh="refreshData"
          />
        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Part') + ' ' + 4"
          @click="!main ? $router.push({ name: 'my-performance', params: { id, tabIdx: 3 } }) : ''"
        >
          <performance-part-4

            @submit-all="refreshData = true"
          />
        </b-tab>
      </b-tabs>
      <!-- Tab End -->
      <!-- <KRAModule class="mt-2" /> -->

    </div>

  </div>
</template>
<script>
import {
  BTabs,
  BTab,
} from "bootstrap-vue"
// import KRAModule from '@/views/pages/performance/my-performance/KRA/KRA-main.vue'
import SummaryKRA from '@/views/pages/performance/my-performance/KRA/summary-kra.vue'
import SummaryJDNonExec from '@/views/pages/performance/my-performance/summaryJd-nonexec.vue'
import SummaryKRANonExec from '@/views/pages/performance/my-performance/summaryKra-nonexec.vue'
// import PerformancePart1 from "@/pages/non-exec/JD/Part-1.vue"
import PerformancePart2 from "@/pages/exec/JD-KRA-User/KRA/Part-2.vue"
import PerformancePart3 from "@/pages/exec/JD-KRA-User/KRA/Part-3.vue"
import PerformancePart4 from "@/pages/exec/JD-KRA-User/KRA/Part-4.vue"
// import PerformancePart5 from "@/pages/non-exec/KRA/Part-1-new.vue"
import profile from '@/component/29_profile_summary.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BTabs,
    BTab,
    SummaryKRA,
    SummaryJDNonExec,
    SummaryKRANonExec,
    // KRAModule,
    profile,
    // PerformancePart1,
    PerformancePart2,
    PerformancePart3,
    PerformancePart4,
    // PerformancePart5,

  },
  props: {
    main: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {
      id: this.$route.params.id,
      tabNo: this.$route.params.tabIdx,
      tabList: ["Tab 1", "Tab 2", "Tab 3", "Tab 4"],
      setting: [],
      refreshData: false,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  beforeMount() {
    // console.log('tab no', this.$route.params.tabIdx)
    // console.log('id', this.$route.params.id)
  },

  // beforeMount() {
  //   this.id = this.$route.params.id
  // },
  mounted(){
    this.getUserSet()
    // console.log("refresh", this.refreshData)
  },
  methods: {
    getUserSet() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/getUserPerformanceSet`, data).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.setting = response.data.data.jd_kra
        // console.log(this.kra)
      })
    },
  },
}
</script>
