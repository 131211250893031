<template>
  <div>
    <b-card
      class="m-0 mb-2"
      style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              text-align: center;
              "
    >
      <div class="pl-1 pt-1 d-flex align-items-center">
        <b-button
          variant="primary"
          @click="toPart1(id)"
        >
          {{ $t('See Details') }}
        </b-button>
      </div>

      <div
        class="mt-2 mx-1"
      >
        <b-table
          show-empty
          :items="summaryList"
          :fields="fields"
          bordered
          :fixed="true"

          class="customTable"
        >
          <!-- A custom formatted column -->
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #head()="data">
            <span
              class="saj-subtitle"
            >{{ $t(data.label) }}</span>
          </template>

        </b-table>
      </div>

      <div
        class="mt-3 mx-1"
      >
        <b-table
          show-empty
          :items="summaryListSide"
          :fields="fields2"
          bordered
          :fixed="true"

          class="customTable"
        >
          <!-- A custom formatted column -->
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #head()="data">
            <span
              class="saj-subtitle"
            >{{ $t(data.label) }}</span>
          </template>

        </b-table>
      </div>

    </b-card>
  </div>
</template>
<script>
import Ripple from "vue-ripple-directive"
// import ApproveBulkEmployee from "@/component/hod/ApproveBulkEmployee.vue"
import {
  BCard,
  BTable,
  // BImg,
  BButton,
} from "bootstrap-vue"

export default {
  components: {
    BCard,
    BTable,
    // BImg,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
    //   showEdit: null,
      show: true,
      id: this.$route.params.id,
      summaryList: [],
      summaryListSide: [],
      fields: [
        { key: 'main_task_field', label: 'Main Task', thClass: 'text-center' },
        {
          key: 'target', label: 'Target', thClass: 'text-center',
        },
        {
          key: 'yearly_achievement', label: 'Yearly Achievement', thClass: 'text-center',
        },
      ],
      fields2: [
        { key: 'side_task_summary', label: 'Additional Task', thClass: 'text-center' },
        {
          key: 'target', label: 'Target', thClass: 'text-center',
        },
        {
          key: 'yearly_achievement', label: 'Yearly Achievement', thClass: 'text-center',
        },
      ],

    }
  },
  computed: {

  },
  mounted() {
    this.getSummaryKRA()
    this.getSummaryKRASide()
  },
  methods: {
    toPart1(id) {
      // to jd
      this.$router.push({ name: 'my-performance-nonexec-part1-kra', params: { id } })
    },

    getSummaryKRA() {
      this.$axios.get(`${this.$baseUrl}/myperformances/main_task?cycle_id=${this.id}`).then(response => {
        this.summaryList = response.data.data.main_task
      })
    },
    getSummaryKRASide() {
      this.$axios.get(`${this.$baseUrl}/myperformances/side_task?cycle_id=${this.id}`).then(response => {
        this.summaryListSide = response.data.data.side_task
      })
    },

  },

}
</script>
<style>
