<template>
  <div>
    <b-card
      class="m-0 mb-2"
      style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              text-align: center;
              "
    >
      <div class="pl-1 pt-1 d-flex align-items-center">
        <b-button
          variant="primary"
          @click="toPart1(id)"
        >
          {{ $t('See Details') }}
        </b-button>
      </div>

      <div
        class="mt-2 mx-1"
      >
        <b-table
          show-empty
          :items="summaryList"
          :fields="fields"
          responsive=""
          bordered
          class="custom_td"
        >
          <!-- A custom formatted column -->
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #head()="data">
            <span
              class="saj-subtitle"
            >{{ $t(data.label) }}</span>
          </template>
          <template
            #cell(job_task)="data"
          >
            {{ data.item.job_task }}
          </template>
          <template
            #cell(job_subtask)="data"
          >
            {{ data.item.job_subtask }}
          </template>

        </b-table>
      </div>

    </b-card>
    <div
      class=""
      style="height: 2vh;"
    />

  </div>
</template>
<script>
import Ripple from "vue-ripple-directive"
// import ApproveBulkEmployee from "@/component/hod/ApproveBulkEmployee.vue"
import {
  BCard,
  BTable,
  // BImg,
  BButton,
} from "bootstrap-vue"

export default {
  components: {
    BCard,
    BTable,
    // BImg,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
    //   showEdit: null,
      show: true,
      id: this.$route.params.id,
      summaryList: [],
      fields: [
        { key: 'job_task', label: 'Main Task', thClass: 'text-center' },
        // {
        //   key: 'task_score', label: 'Main Task Score', thClass: 'text-center',
        // },
        {
          key: 'job_subtask', label: 'Additional Task', thClass: 'text-center',
        },
        // { key: 'subtask_score', label: 'Sub Task Score', thClass: 'text-center' },
      ],

    }
  },
  computed: {

  },
  mounted() {
    this.getSummaryJD(

    )
  },
  methods: {
    toPart1(id) {
      // to jd
      this.$router.push({ name: 'my-performance-nonexec-part1', params: { id } })
    },

    getSummaryJD() {
      this.$axios.get(`${this.$baseUrl}/myperformances/job_description?cycle_id=${this.id}`).then(response => {
        // console.log(response)
        // this.summaryList = response.data.data.job_description
        if (response.data.data.job_description !== null){
          const a = response.data.data.job_description
          const tempList = []

          tempList.push(a)

          // console.log(tempList)
          this.summaryList = tempList
        }
      })
    },

  },

}
</script>
<style>
.custom_td .table td{
  width: 50%;
  white-space: pre-line;
  vertical-align: top;
  text-align: left;
}
</style>
