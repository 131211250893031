<template>
  <div
    class=""
  >

    <!-- START ALL FORM's PART -->
    <div>
      <performance-global :main="false" />
    </div>

    <!-- Objective: {{ createdObjective.length === 0 }}<br> -->
    <!-- Tab:{{ currentPart.id }}<br>
    Planning: {{ submittedPlanning }}<br>
    Review: {{ submittedReview === null ? 'null' : submittedReview }}<br>
    Result: {{ submittedResult === null ? 'null' : submittedResult }}<br> -->
    <!-- Submit: {{ isSubmitted }} -->
    <div
      class="d-flex mt-1"
    >
      <!-- <div
        v-for="part in allPart"
        :key="part.id"
        :class="createdObjective.length === 0 ? '' : 'pointer'"
        class="mt-1 mr-1 mb-2"
        :style="part.id === currentPart.id? {'border-bottom': '3.5px solid #FF0000'} : {}"
        @click="createdObjective.length === 0 ? '' :currentPart = part"
      >
        <span :style="[createdObjective.length === 0 ? {color: 'black', opacity: '0.6'} : {color: 'black'}]">{{ $t(part.title) }}</span>
      </div> -->
      <!-- {{ currentPart.id }} -->
      <div
        v-for="part in allPart"
        :key="part.id"
        :class="(part.id === 'evaluation'&& submittedReview === 0 && submittedResult === 0) || (part.id === 'review' && submittedReview === 0 && submittedPlanning === 0) ? '' : 'pointer'"
        class="mt-1 mr-1 mb-2"
        :style="part.id === currentPart.id ? {'border-bottom': '3.5px solid #FF0000'} : {}"
        @click="(part.id === 'evaluation'&& submittedReview === 0 && submittedResult === 0) || (part.id === 'review' && submittedReview === 0 && submittedPlanning === 0) ? '' :currentPart = part"
      >
        <span :style="[(part.id === 'evaluation'&& submittedReview === 0 && submittedResult === 0) || (part.id === 'review' && submittedReview === 0 && submittedPlanning === 0) ? {color: 'black', opacity: '0.6'} : {color: 'black'}]">{{ $t(part.title) }}</span>
      </div>

    </div>
    <!-- END ALL FORM's PART -->
    <!-- START FORM -->
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <template #overlay>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
          <p class="mt-1">
            {{ $t('Fetching Data') }}...
          </p>
        </div>

      </template>
      <div
        class="p-1 d-flex flex-column mb-1"
        style="background-color: white;"
      >
        <div
          name="fade-custom"
          mode="out-in"
        >
          <div
            v-if="currentPart.id === 'setKra'"
            key="currentPartSet"
            class="d-flex align-items-center justify-content-between"
          >
            <div
              class="formTitle saj-title"
            >
              {{ $t ("Performance Planning") }}
            </div>
            <div
              v-if="isSubmitted"
              class="d-flex align-items-center justify-content-center"
            >
              <div
                v-b-tooltip.hover.left="$t('Your planning has been saved')"
                class="btn btn-success"
                style="pointer: default;"
                :style="approved_at === null ? '' : 'display: none;'"
              >
                {{ $t("Saved") }}
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <button
                v-if="!isSavingPlanning"
                class="btn btn-primary saj-button"
                :disabled="scoreCards.length === 0"
                @click="saveKra(), isSavingPlanning = true"
              >
                {{ $t ("Save Changes") }}
              </button>
              <button
                v-if="isSavingPlanning"
                class="btn btn-primary saj-button"
              >
                <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
              </button>
              <button
                class="ml-1 btn btn-primary saj-button buttonSize"
                :disabled="createdObjective.length === 0"
                @click="submitKra()"
              >
                {{ $t ("Next") }}
              </button>
            </div>
          </div>
          <div
            v-else-if="currentPart.id === 'review'"
            key="currentPartReview"
            class="d-flex align-items-center justify-content-between"
          >
            <span
              class="formTitle saj-title"
            >
              {{ $t ("Performance Review") }}
            </span>
            <div
              v-if="isSubmitted"
              class="d-flex align-items-center justify-content-center"
            >
              <div
                v-b-tooltip.hover.left="$t('Your review has been saved')"
                class="btn btn-success"
                style="pointer: default;"
                :style="approved_at === null ? '' : 'display: none;'"
              >
                {{ $t("Saved") }}
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <button
                v-if="!isSavingReview"
                class="btn btn-primary saj-button"
                :disabled="scoreCards.length === 0"
                :style="approved_at === null ? '' : 'display: none;'"
                @click="saveReview(), isSavingReview = true"
              >
                {{ $t ("Save Changes") }}
              </button>
              <button
                v-if="isSavingReview"
                class="btn btn-primary saj-button"
              >
                <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
              </button>
              <button
                :disabled="isSavedReview === 0"
                class="ml-1 btn btn-primary saj-button buttonSize"
                :style="approved_at === null ? '' : 'display: none;'"
                @click="submitReview()"
              >
                {{ $t ("Next") }}
              </button>
            </div>

          </div>
          <div
            v-else-if="currentPart.id === 'evaluation'"
            key="currentPartEvaluation"
            class="d-flex align-items-center justify-content-between"
          >
            <span
              class="formTitle saj-title"
            >
              {{ $t ("Year End Result") }}
            </span>
            <div
              v-if="isSubmitted"
              class="d-flex align-items-center justify-content-center"
            >
              <div
                v-b-tooltip.hover.left="$t('Your result has been saved')"
                class="btn btn-success"
                style="pointer: default;"
                :style="approved_at === null ? '' : 'display: none;'"
              >
                {{ $t('Completed') }}
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <button
                v-if="!isSavingResult"
                class="btn btn-primary saj-button"
                :style="approved_at === null ? '' : 'display: none;'"
                :disabled="scoreCards.length === 0"
                @click="saveResult() , isSavingResult = true"
              >
                {{ $t ("Save Changes") }}
              </button>
              <button
                v-if="isSavingResult"
                class="btn btn-primary saj-button"
              >
                <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
              </button>
              <button
                :disabled="isSavedResult === 0"
                class="ml-1 btn btn-primary saj-button"
                :style="approved_at === null ? '' : 'display: none;'"
                @click="submitResult()"
              >
                {{ $t("Complete") }}
              </button>
            </div>
          </div>
          <div
            v-if="currentPart.id === 'evaluation'"
            class="row d-flex justify-content-end m-1"
          >
            <div
              v-if="roles.isExecutive"
              class="saj-title mt-1 d-flex justify-content-end"
            >
              {{ total_score_exec === '' ? 0 : total_score_exec }}/{{ kra_weightage }} %
            </div>
            <div
              v-if="roles.isTopManagement"
              class="saj-title mt-1 d-flex justify-content-end"
            >
              {{ total_score_top === '' ? 0 : total_score_top }}/{{ kra_weightage }} %
            </div>
          </div>
        </div>
        <button
          v-if="currentPart.id === 'setKra' && !isSubmitted"
          class="saj-text btn btn-primary mt-1"
          style="width: 25rem"
          :disabled="indicator.status === 'Completed'"
          @click="addScoreBoard(
          )"
        >
          {{ $t ("Create Department's Objective / Initiative") }}
        </button>
        <button
          v-if="currentPart.id === 'review' && !isSubmitted"
          class="saj-text btn btn-primary mt-1"
          style="width: 25rem"
          :disabled="indicator.status === 'Completed'"
          @click="addScoreBoard(
          )"
        >
          {{ $t ("Create Department's Objective / Initiative") }}
        </button>
        <button
          v-if="currentPart.id === 'evaluation' && !isSubmitted"
          class="saj-text btn btn-primary mt-1"
          style="width: 25rem"
          :disabled="indicator.status === 'Completed'"
          @click="addScoreBoard(
          )"
        >
          {{ $t ("Create Department's Objective / Initiative") }}
        </button>
        <div class="mt-2 d-flex flex-column px-1">
          <template v-if="scoreCards.length > 0">
            <transition-group
              name="list"
              mode="out-in"
            >
              <div
                v-for="(scoreCard, indexScoreBoard) in scoreCards"
                :key="scoreCard.id"
                style="position: relative;background: #F7F7FC;
                  border-radius: 6px;"
                class="mb-1"
              >
                <!-- {{ scoreCard.newTempObjective === 'newObjective' ? 'New Objective' : 'old Objective' }} -->
                <!-- #REMOVE SPECIFIC SCORE BOARD - START -->
                <transition
                  name="zoom-out"
                  mode="out-in"
                >
                  <!-- v-if="!isSubmitted && createdObjective.length !== 0 || scoreCard.newTempObjective === 'newObjective'" -->
                  <template v-if="!isReadOnly && !isSubmitted && createdObjective.length !== 0 ">
                    <div
                      key="removeSpecificScoreBoard"
                      style="position: absolute; right: -5px; top: -10px; background-color: white; border-radius: 50%;"
                      @click="showAlertModal('objective-task',
                                             {
                                               id:scoreCard.id,
                                               index: indexScoreBoard
                                             }
                      )"
                    >
                      <feather-icon
                        icon="MinusCircleIcon"
                        class=""
                        style="color: #C5C7CD"
                        size="20"
                      />
                    </div>
                  </template>

                </transition>
                <!-- #REMOVE SPECIFIC SCORE BOARD - START -->
                <!-- #SCORE CARD PANEL - START -->
                <div
                  class="d-flex align-items-center justify-content-between pointer p-2"
                  @click="scoreCard.visible = !scoreCard.visible"
                >
                  <span class="saj-text">{{ $t(scoreCard.title) }} {{ indexScoreBoard + 1 }}</span>
                  <feather-icon
                    :icon="scoreCard.visible? 'ChevronUpIcon': 'ChevronDownIcon'"
                    size="20"
                    style="color: #6E6B7B;"
                  />
                </div>
                <!-- #SCORE CARD PANEL - END -->
                <!-- #SCORE CARD COLLAPSE PANEL - START -->
                <validation-observer ref="validateKRA">
                  <b-collapse
                    v-model="scoreCard.visible"
                  >

                    <div
                      class="px-1"
                      :class="isReadOnly ? 'pb-2' : ''"
                    >
                      <validation-observer ref="validateSAVE">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('objective')"
                          rules="required"
                        >
                          <!-- {{ KRA }} -->
                          <!-- {{ currentPart.id === 'evaluation'? 'evaluation': 'not evaluation' }}----- -->
                          <!-- {{ scoreCard }} -->
                          <!-- {{ scoreCard.newTempObjective === 'newObjective' ? 'New Objective' : 'old Objective' }} -->
                          <textarea
                            v-model="scoreCard.description"
                            style="width: 100%; min-height: 70px;"
                            :readonly="isReadOnly || isSubmitted || currentPart.id === 'review' ? scoreCard.newTempObjective !== 'newObjective' : false"
                            :style="isSubmitted || (currentPart.id === 'review' && scoreCard.newTempObjective !== 'newObjective') || scoreCard.newTempObjective !== 'newObjective' ? {background: '#EEEEEE'} : { background: '#FFFFFF'}"
                            class="custom-formcontrol-none mt-1"
                          />
                          <!-- #EEEEEE = grey -->
                          <!-- #FFFFFF = white -->
                          <small
                            class="text-danger"
                            style="font-family: Poppins"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </validation-observer>
                      <transition-group
                        name="list"
                        mode="out-in"
                      >
                        <div
                          v-for="(KRA, indexKRA) in scoreCard.KRA"
                          :key="KRA.id"
                          style="background: #efedee; border-radius: 6px;"
                          class="p-1 mb-1"
                        >
                          <!-- #KRA PANEL - START -->
                          <div
                            class="d-flex align-items-center justify-content-between pointer"
                            @click="KRA.visible = !KRA.visible"
                          >
                            <span class="saj-text">
                              {{ $t ("KEY RESULT AREA") }} {{ indexKRA + 1 }}</span>
                            <feather-icon
                              :icon="KRA.visible? 'ChevronUpIcon': 'ChevronDownIcon'"
                              size="20"
                              style="color: #6E6B7B;"
                            />
                          </div>
                          <!-- #KRA PANEL - END -->
                          <!-- #KRA COLLAPSE PANEL - START -->
                          <b-collapse
                            v-model="KRA.visible"
                          >
                            <div class="mt-2 mb-2">
                              <div
                                class="d-flex  justify-content-between align-items-center"
                                style="margin-bottom: 25px;"
                              >
                                <div
                                  class="d-flex flex-column"
                                  style="width: 100%;"
                                >
                                  <validation-observer ref="validateSAVE">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('KRA')"
                                      rules="required"
                                    >
                                      <!-- kra name ->
                                  {{ KRA.description !== null ? 'ade kra name' : KRA.description !== null }} -->
                                      <!-- {{ newScoreCards.newTemp === indexKRA || scoreCard.KRA.length !== scoreCards.length }}ja -->
                                      <!-- {{ newScoreCards.newTemp }} {{ indexKRA }} lalala -->
                                      <!-- {{ KRA.newTemp === 'newScore' ? 'new kra' : 'old kra' }} -->
                                      <!-- TYPE:{{ KRA.type }}
                                      {{ KRA }} -->
                                      <!-- hey{{ KRA.newTemp }} -->
                                      <!-- ID: {{ id }}<br> -->
                                      <!-- KRA {{ KRA }} -->
                                      <!-- {{ currentPart.id }} -->

                                      <textarea
                                        v-model="KRA.description"
                                        :readonly="currentPart.id === 'evaluation'&& (KRA.type !== 'result'? isReadOnly : !isReadOnly) || isSubmitted || currentPart.id === 'review' ? !(KRA.newTemp === 'newScore') : false"
                                        :style="!(KRA.newTemp === 'newScore') && currentPart.id === 'evaluation' && (KRA.type !== 'result'? isReadOnly : !isReadOnly) || isSubmitted || (currentPart.id === 'review' && !(KRA.newTemp === 'newScore')) ? {background: '#EEEEEE'} : { background: '#FFFFFF'}"
                                        style="width: 100%; min-height: 70px;"
                                        class="custom-formcontrol-none"
                                      />
                                      <small
                                        class="text-danger"
                                        style="font-family: Poppins"
                                      >{{ errors[0] }}</small>
                                    </validation-provider>
                                  </validation-observer>
                                  <div class="d-flex flex-column">
                                    <transition-group
                                      name="list"
                                      mode="out-in"
                                    >
                                      <template>

                                        <div
                                          :key="`${KRA.id}+target`"
                                          class="mt-1 d-flex justify-content-between align-items-center"
                                        >
                                          <div
                                            style="background: #f7f7fd;
                                  border-radius: 6px; width: 100%;"
                                            class="p-1"
                                          >
                                            <!-- #KRA TARGET - START -->
                                            <template>
                                              <!-- replace everything in <template> with actual component -->
                                              <div>
                                                <validation-observer ref="validateKRA">
                                                  <comp-target
                                                    :measurement.sync="KRA.target.measurement"
                                                    :target.sync="KRA.target.target"
                                                    :date.sync="KRA.target.due"
                                                    :attachment-kra.sync="KRA.target.attachmentKra"
                                                    :current-part="currentPart"
                                                    :is-file-kra="KRA.target.isFileKra"
                                                    :is-submitted="isSubmitted"
                                                    :is-type="KRA.type"
                                                    :is-new-temp="KRA.newTemp"
                                                    :is-approved-at="approved_at"
                                                  />
                                                </validation-observer>
                                              </div>
                                            </template>
                                            <!-- #KRA TARGET - END -->
                                          </div>
                                        </div>
                                      </template>
                                      <template v-if="isShowAchievement">
                                        <div
                                          :key="`${KRA.id}+achievement`"
                                          class="mt-1 d-flex justify-content-between align-items-center"
                                        >
                                          <div
                                            style="background: #f7f7fd;
                                          border-radius: 6px; width: 100%;"
                                            class="p-1"
                                          >
                                            <!-- #KRA ACHIEVEMENT - START -->
                                            <template>
                                              <!-- replace everything in <template> with actual component -->
                                              <div>
                                                <validation-observer ref="validateKRA">
                                                  <comp-achievement
                                                    :tracking.sync="KRA.achievement.tracking"
                                                    :achievement.sync="KRA.achievement.achievement"
                                                    :yearachievement.sync="KRA.achievement.yearachievement"
                                                    :attachment.sync="KRA.achievement.file"
                                                    :resultattachment.sync="KRA.achievement.resultfile"
                                                    :is-file="KRA.achievement.isFile"
                                                    :is-file-result="KRA.achievement.isFileResult"
                                                    :achievement_to_date.sync="KRA.achievement.achievement_to_date"
                                                    :current-part="currentPart"
                                                    :is-submitted="isSubmitted"
                                                    :is-approved-at="approved_at"
                                                    :is-new-temp="KRA.newTemp"
                                                  />
                                                </validation-observer>
                                              </div>

                                            </template>

                                          <!-- oii   {{ KRA.id }} -->
                                            <!-- #KRA ACHIEVEMENT - END -->
                                          </div>
                                        </div>
                                      </template>
                                    </transition-group>
                                  </div>
                                </div>
                                <!-- #REMOVE SPECIFIC KRA - START -->
                                <transition
                                  name="zoom-out"
                                  mode="out-in"
                                >

                                  <template v-if="!isReadOnly && !isSubmitted && createdObjective.length !== 0 && KRA.newTemp !== 'newScore' || !KRA.type === 'result'">
                                    <feather-icon
                                      icon="MinusCircleIcon"
                                      class="ml-2"
                                      style="color: #C5C7CD"
                                      size="35"
                                      @click="showAlertModal('kra-task',

                                                             {
                                                               id: KRA.id,
                                                               index: indexKRA
                                                             }
                                      )"
                                    />
                                  </template>
                                  <!-- year end result -->
                                  <template v-else-if="isReadOnly && !isSubmitted && KRA.newTemp !== 'newScore'">
                                    <feather-icon
                                      icon="MinusCircleIcon"
                                      class="ml-2"
                                      style="color: #C5C7CD"
                                      size="35"
                                      @click="showAlertModal('kra-task',

                                                             {
                                                               id: KRA.id,
                                                               index: indexKRA
                                                             }
                                      )"
                                    />
                                  </template>

                                </transition>
                                <!-- #REMOVE SPECIFIC KRA - END -->

                              </div>

                              <hr style="border-top: 1px solid #D5D5D5; width: 100%;">
                              <div class="d-flex">
                                <div
                                  class="d-flex flex-column"
                                  style="width: 100%;"
                                >
                                  <span
                                    class="saj-text"
                                  >{{ $t ("Remarks") }}</span>
                                  <!-- sini{{ KRA.newTemp }} -->
                                  <!-- <validation-observer ref="validateKRA">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Remarks')"
                                      rules="required"
                                      class=""
                                    > -->
                                  <textarea
                                    v-model="KRA.remark"
                                    :disabled="isSubmitted || (approved_at !== null && !isSubmitted) && !KRA.newTemp === 'newScore'"
                                    :style="isSubmitted || (approved_at !== null && !isSubmitted) && !KRA.newTemp === 'newScore' ? {background: '#EEEEEE'} : { background: '#FFFFFF'}"
                                    style="width: 100%; min-height: 70px; margin-bottom: 10px; margin-top: 10px;"
                                    class="custom-formcontrol-none"
                                  />
                                  <!-- <small
                                        class="text-danger"
                                        style="font-family: Poppins"
                                      >{{ errors[0] }}</small>
                                    </validation-provider>
                                  </validation-observer> -->
                                </div>
                                <transition
                                  name="zoom-out"
                                  mode="out-in"
                                >
                                  <template v-if="isShowSelfScore">
                                    <div v-if="currentPart.id === 'review'">
                                      <div
                                        key="selfScore"
                                        class="ml-2 self-score"
                                      >
                                        <span style="">{{ $t ("Self Score") }}:</span>

                                        <b-form-rating
                                          v-model="KRA.self_score"
                                          show-clear
                                          :disabled="isSubmitted "
                                          class="pl-0"
                                          no-border
                                          show-value
                                          inline
                                          color="#DFB300"
                                          stars="6"
                                          size="lg"
                                          style="background: none"
                                        />

                                        <!-- score: {{ KRA.self_score }} -->
                                        <span>
                                          <h5
                                            v-if="KRA.self_score == '1'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                          <h5
                                            v-else-if="KRA.self_score == '2'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                          <h5
                                            v-else-if="KRA.self_score == '3'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                                          <h5
                                            v-else-if="KRA.self_score == '4'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                                          <h5
                                            v-else-if="KRA.self_score == '5'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                          <h5
                                            v-else-if="KRA.self_score == '6'"
                                            style="margin-left:5px"
                                          >{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                                        </span>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <validation-observer ref="validateKRA">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Self Score')"
                                          :rules="{required, min_value: 1}"
                                        >
                                          <div
                                            key="selfScore"
                                            class="ml-2 self-score"
                                          >
                                            <span style="">{{ $t ("Self Score") }}:</span>

                                            <b-form-rating
                                              v-model="KRA.self_score"
                                              show-clear
                                              :disabled="isSubmitted "
                                              class="pl-0"
                                              no-border
                                              show-value
                                              inline
                                              color="#DFB300"
                                              stars="6"
                                              size="lg"
                                              style="background: none"
                                            />
                                            <span>
                                              <h5
                                                v-if="KRA.self_score == '1'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                              <h5
                                                v-else-if="KRA.self_score == '2'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                              <h5
                                                v-else-if="KRA.self_score == '3'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                                              <h5
                                                v-else-if="KRA.self_score == '4'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                                              <h5
                                                v-else-if="KRA.self_score == '5'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                              <h5
                                                v-else-if="KRA.self_score == '6'"
                                                style="margin-left:5px"
                                              >{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                                            </span>
                                            <div
                                              v-if="currentPart.id === 'evaluation'"
                                              class="
                                          self-score
                                          d-flex
                                          justify-content-start
                                          align-items-center
                                          m-0
                                          p-0
                                          mt-1
                                        "
                                            >
                                              {{ $t("Manager Score") }}: {{ KRA.manager_rating === undefined ? 0 : KRA.manager_rating }}/6
                                            </div>
                                          </div>
                                          <small
                                            class="text-danger ml-2"
                                            style="font-family: Poppins"
                                          >{{ errors[0] }}</small>
                                        </validation-provider>
                                      </validation-observer>
                                    </div>
                                    <!-- <validation-observer ref="validateKRA">
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="$t('Self Score')"
                                        :rules="{required, min_value: 1}"
                                      >
                                        <div
                                          key="selfScore"
                                          class="ml-2 self-score"
                                        >
                                          <span style="">{{ $t ("Self Score") }}:</span>

                                          <b-form-rating
                                            v-model="KRA.self_score"
                                            show-clear
                                            :disabled="isSubmitted "
                                            class="pl-0"
                                            no-border
                                            show-value
                                            inline
                                            color="#DFB300"
                                            stars="6"
                                            size="lg"
                                            style="background: none"
                                          />
                                          <span>
                                            <h5
                                              v-if="KRA.self_score == '1'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                            <h5
                                              v-else-if="KRA.self_score == '2'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                            <h5
                                              v-else-if="KRA.self_score == '3'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                                            <h5
                                              v-else-if="KRA.self_score == '4'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                                            <h5
                                              v-else-if="KRA.self_score == '5'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                            <h5
                                              v-else-if="KRA.self_score == '6'"
                                              style="margin-left:5px"
                                            >{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                                          </span>
                                          <div
                                            v-if="currentPart.id === 'evaluation'"
                                            class="
                                        self-score
                                        d-flex
                                        justify-content-start
                                        align-items-center
                                        m-0
                                        p-0
                                        mt-1
                                      "
                                          >
                                            {{ $t("Manager Score") }}: {{ KRA.manager_rating === undefined ? 0 : KRA.manager_rating }}/6
                                          </div>
                                        </div>
                                        <small
                                          class="text-danger ml-2"
                                          style="font-family: Poppins"
                                        >{{ errors[0] }}</small>
                                      </validation-provider>
                                    </validation-observer> -->
                                  </template>
                                </transition>

                              </div>
                            </div>
                          </b-collapse>
                          <!-- #KRA COLLAPSE PANEL - END -->
                        </div>
                      </transition-group>

                      <transition
                        name="zoom-out"
                        mode="out-in"
                      >
                        <template v-if="!isSubmitted ">
                          <div
                            key="addKRA"
                            class="mt-1 d-flex align-items-center justify-content-center"
                          >
                            <feather-icon
                              icon="PlusCircleIcon"
                              class="ml-2 mb-1"
                              style="color: #C5C7CD"
                              size="35"
                              @click="addKRA(
                                {
                                  id: scoreCard.id,
                                  index: indexScoreBoard
                                }
                              )"
                            />
                          </div>
                        </template>
                      </transition>
                    </div>

                  </b-collapse>
                </validation-observer>
              <!-- #SCORE CARD COLLAPSE PANEL - END -->

              </div>
              <!-- START - If score boards empty -->
              <div
                v-if="scoreCards.length === 0"
                key="emptyScoreBoard"
              >
                <span style="color; #6E6B7B">{{ $t("No score card yet. Please create your score card.") }} </span>
              </div>
            <!-- END - If score boards empty -->
            </transition-group>
          </template>
        </div>
      </div>
    </b-overlay>
    <!-- END FORM -->
    <b-modal
      id="delete-kra"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click Yes to delete Key Result Area.')"
        confirm-btn="Yes"
        @cancel="hideAlertModal('kra-task')"
        @confirm="removeSpecificKRa(deleteIdKra)"
      />
    </b-modal>
    <b-modal
      id="delete-objective"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click Yes to delete Objective / Initiative.')"
        confirm-btn="Yes"
        @cancel="hideAlertModal('objective-task')"
        @confirm="removeSpecificScoreBoard(deleteIdObjective)"
      />
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
  .custom-formcontrol-none {
    // outline-color: red;
    border: 1px solid #d8d6de !important;
    text-overflow: ellipsis;
    background: '#FFFFFF';
    opacity: 0.9;
    backdrop-filter: blur(20px);
    width: 70%;
    height: 3.0rem;
    padding: 0.438rem 1rem;
    background-clip: padding-box;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  .custom-formcontrol-none:focus{
    outline: 0.01rem solid #ff0000 !important;
  }
.formTitle {
  color: #FF0000;
}
.buttonSize{
  width: 180px;
  height: 60px;
  font-size: 1.7em;
}
</style>
<script>
import {
  BCollapse,
  BFormRating,
  VBTooltip,
  VBPopover,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import CompTarget from '@/component/33-target.vue'
import CompAchievement from '@/component/34-achievement.vue'
import _ from 'lodash'
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'
import Ripple from "vue-ripple-directive"
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'
import performanceGlobal from '@/views/pages/performance/my-performance/my-performance-global.vue'
import AlertModal from '@/pages/alert-modal.vue'

extend('min_value', min_value)

export default {
  components: {
    BOverlay,
    BSpinner,
    BCollapse,
    CompTarget,
    CompAchievement,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    performanceGlobal,
    AlertModal,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {

  },
  data(){
    return {
      show: true,
      isSavedReview: null,
      isSavedResult: null,
      submittedPlanning: null,
      submittedReview: null,
      submittedResult: null,
      deleteIdKra: null,
      isSuccessReview: false,
      isSuccessResult: false,
      isSavingPlanning: false,
      isSavingReview: false,
      isSavingResult: false,
      newScoreCards: {
        newTemp: "",
      },
      newObjectiveCards: {
        newTempObjective: "",
      },
      indicator: "",
      required,
      id: this.$route.params.id,
      allPart: [
        {
          title: 'Performance Planning',
          id: 'setKra',
        },
        {
          title: 'Performance Review',
          id: 'review',
        },
        {
          title: 'Year End Result',
          id: 'evaluation',
        },
      ],
      isSubmitted: false,
      currentPart: {
        title: null,
        id: null,
      },
      scoreCards: [
      ],
      total_score_exec: "",
      total_score_top: "",
      approved_at: null,
      createdObjective: [],
      rating: [],
      kra_weightage: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    isShowSelfScore() {
      const showSelfScore = ['review', 'evaluation']
      return showSelfScore.includes(this.currentPart.id)
    },
    isReadOnly() {
      // const readOnly = ['review', 'evaluation']
      const readOnly = ['evaluation']
      return readOnly.includes(this.currentPart.id)
    },
    isShowAchievement() {
      const showAchievement = ['review', 'evaluation']
      return showAchievement.includes(this.currentPart.id)
    },
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    currentPart: {
      handler(part) {
        this.whichPartNow(part)
      },
    },
  },
  mounted() {
    this.getScoreRating()
    this.weightage()
    // eslint-disable-next-line prefer-destructuring
    this.currentPart = this.allPart[0]
    setTimeout(() => {
      this.getDetails()
      this.getPlanning()
      this.getReview()
      this.getResult()
    }, 100)
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.userDetails.business_unit)
      params.append('role_id', this.userDetails.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.kra_weightage = response.data.data.key_result_area
      })
    },
    whichPartNow(part = this.currentPart) {
      this.newScoreCards = {
        newTemp: "",
      }
      this.newObjectiveCards = {
        newTempObjective: "",
      }
      switch (part.id){
        case 'setKra':
          setTimeout(() => {
            this.getPlanning()
          }, 500)
          break
        case 'review':
          setTimeout(() => {
            this.getReview()
          }, 500)
          break
        case 'evaluation':
          setTimeout(() => {
            this.getResult()
          }, 500)
          break
        default:
      }
    },
    removeSpecificScoreBoard(scoreBoardIndex) {
      // this.scoreCards.splice(scoreBoardIndex.index, 1)
      const data = new FormData()
      data.append('objective_id', scoreBoardIndex.id)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/kra/delete_objective`,
        data,
      }

      this.$axios(config)
        .then(() => {
          this.whichPartNow()
          this.hideAlertModal()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.scoreCards.splice(scoreBoardIndex.index, 1)
        })
    },
    removeSpecificKRa(KRAItem) {
      // this.scoreCards[scoreCard.index].KRA.splice(KRAItem.index, 1)
      const data = new FormData()
      data.append('kra_id', KRAItem.id)
      // console.log("id", KRAItem.id)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/kra/delete_kra`,
        data,
      }

      this.$axios(config)
        .then(() => {
          this.whichPartNow()
          this.hideAlertModal()
        })
        .catch(() => {
          this.KRA.splice(KRAItem.index, 1)
          // console.log("tak berjaya")
        })
    },
    showAlertModal(type, id) {
      // console.log("masukkkk", type, id)
      switch (type){
        case 'kra-task':
          this.$bvModal.show('delete-kra')
          this.deleteIdKra = id
          // console.log("dapat ke id kra:", this.deleteIdKra)
          break
        case 'objective-task':
          this.$bvModal.show('delete-objective')
          this.deleteIdObjective = id
          // console.log("dapat ke id obj:", this.deleteIdObjective)
          break
        default:
      }
    },

    hideAlertModal(type) {
      switch (type){
        case 'kra-task':
          this.$bvModal.hide('delete-kra')
          break
        case 'objective-task':
          this.$bvModal.hide('delete-objective')
          break
        default:
          this.$bvModal.hide('delete-kra')
          this.$bvModal.hide('delete-objective')
      }
    },
    // removeSpecificTarget(scoreCard, KRA, target) {
    //   this.scoreCards[scoreCard.index].KRA[KRA.index].target.splice(target.index, 1)
    // },
    addKRA(scoreCard) {
      const newKRA = {
        newTemp: 'newScore',
        id: `KRA${this.generateRandomString()}`,
        visible: false,
        description: null,
        remark: null,
        self_score: 0,
        target: {
          id: `target${this.generateRandomString()}`,
          measurement: null,
          target: null,
          due: null,
          // attachmentKra: null,
        },
        achievement: {
          tracking: null,
          achievement: null,
          file: null,
        },
      }
      this.newScoreCards = newKRA
      this.scoreCards[scoreCard.index].KRA.push(newKRA)
    },
    generateRandomString() {
      return (+new Date() * Math.random()).toString(36).substring(0, 6)
    },
    addScoreBoard() {
      const newScoreBoard = {
        newTempObjective: 'newObjective',
        id: `ScoreBoard${this.generateRandomString()}`,
        visible: false,
        title: 'Objective / Initiative',
        description: null,
        KRA: [
          {
            newTemp: 'newScore',
            id: `KRA${this.generateRandomString()}`,
            visible: false,
            description: null,
            remark: null,
            self_score: 0,
            target: {
              id: `target${this.generateRandomString()}`,
              visible: false,
              measurement: null,
              target: null,
              due: null,
            },
            achievement: {
              tracking: null,
              achievement: null,
              file: null,
            },
          },
        ],
      }
      this.newObjectiveCards = newScoreBoard
      this.scoreCards.push(newScoreBoard)
    },
    getPlanning() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_planning?cycle_id=${this.id}`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.show = false
            this.approved_at = result.data.approved_at
            this.createdObjective = result.data.objective
            this.submittedPlanning = result.data.submitted
            // console.log('submit planning', this.submittedPlanning)
            const AllObjective = result.data.objective
            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })

                // eslint-disable-next-line no-unused-vars
                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.target
                  // const currentStateFile = itemKRA.file
                  if (tempScoreCards[index].KRA !== undefined){
                    tempScoreCards[index].KRA.push(
                      {
                        id: itemKRA.kra_id,
                        visible: false,
                        description: itemKRA.kra_name,
                        remark: currentState.remarks,
                        target: {
                          id: currentState.id,
                          visible: false,
                          measurement: currentState.measurement,
                          target: currentState.target,
                          due: currentState.due_date,
                          isFileKra: currentState.file,
                        },
                        achievement: {
                          tracking: null,
                          achievement: null,
                          file: null,
                        },
                      },
                    )
                  }
                })
              })

              this.scoreCards = tempScoreCards
            } else {
              this.scoreCards = []
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error.response.data)
        })
    },
    getReview() {
      // console.log('dydcle id', this.id)
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_review?cycle_id=${this.id}`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            // console.log('cycle id', this.id, 'response review', result)
            this.approved_at = result.data.approved_at
            this.submittedReview = result.data.submitted
            this.isSavedReview = result.data.saved
            // console.log('submit review', this.submittedReview)
            const AllObjective = result.data.objective
            // console.log('all obj', AllObjective)
            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  // console.log('previous scoreboard', previousScoreboard)
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })
                // console.log('scoreboards', tempScoreCards)

                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.achievement
                  let visibleKra = false
                  if (this.scoreCards[index].KRA[idx] !== undefined){
                    const previousKra = _.cloneDeep(this.scoreCards[index].KRA[idx])
                    if (this.scoreCards.length > 0) {
                      visibleKra = previousKra.visible === undefined ? false : previousKra.visible
                    }
                  }

                  tempScoreCards[index].KRA.push(
                    {
                      id: itemKRA.kra_id,
                      visible: visibleKra,
                      description: itemKRA.kra_name,
                      remark: currentState.remarks,
                      self_score: currentState.rating === null ? 0 : currentState.rating,
                      type: itemKRA.type,
                      target: {
                        id: currentState.id,
                        visible: false,
                        measurement: currentState.measurement,
                        target: currentState.target,
                        due: currentState.due_date,
                      },
                      achievement: {
                        tracking: currentState.tracking,
                        achievement: currentState.achievement,
                        file: null,
                        isFile: currentState.file,
                        achievement_to_date: currentState.achievement_to_date,
                      },
                    },
                  )
                })
              })

              this.scoreCards = tempScoreCards
            } else {
              this.scoreCards = []
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        // .catch(error => {
        //   const res = error.response
        //   // eslint-disable-next-line no-unused-vars
        //   const resData = error.response.data
        //   if (res.status === 404) {
        //     // eslint-disable-next-line prefer-destructuring
        //     this.currentPart = this.allPart[0]
        //     // alert(resData.message)
        //   }
        // })
    },
    getResult() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/kra/get_result?cycle_id=${this.id}`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.approved_at = result.data.approved_at
            this.submittedResult = result.data.submitted
            this.isSavedResult = result.data.saved
            // console.log('submit result', this.submittedResult)
            this.total_score_exec = result.data.marks
            this.total_score_top = result.data.marks
            const AllObjective = result.data.objective
            if (AllObjective.length > 0) {
              this.isSubmitted = result.data.submitted === 1
              const tempScoreCards = []

              // eslint-disable-next-line no-unused-vars
              AllObjective.forEach((objective, index) => {
                const previousScoreboard = _.cloneDeep(this.scoreCards[index])
                let visibleScoreBoard = false
                if (this.scoreCards.length > 0) {
                  visibleScoreBoard = previousScoreboard.visible
                }
                // eslint-disable-next-line no-unused-vars
                const allKRA = objective.key_result_area
                tempScoreCards.push({
                  id: objective.objective_id,
                  visible: visibleScoreBoard,
                  title: "Objective / Initiative",
                  description: objective.objective_name,
                  KRA: [],
                })

                allKRA.forEach((itemKRA, idx) => {
                  const currentState = itemKRA.result
                  let visibleKra = false
                  if (this.scoreCards[index].KRA[idx] !== undefined){
                    const previousKra = _.cloneDeep(this.scoreCards[index].KRA[idx])
                    if (this.scoreCards.length > 0) {
                      visibleKra = previousKra.visible === undefined ? false : previousKra.visible
                    }
                  }
                  tempScoreCards[index].KRA.push(
                    {
                      id: itemKRA.kra_id,
                      visible: visibleKra,
                      remark: currentState.remarks,
                      description: itemKRA.kra_name,
                      self_score: currentState.rating === null ? 0 : currentState.rating,
                      manager_rating: currentState.manager_rating,
                      type: itemKRA.type,
                      target: {
                        id: currentState.id,
                        visible: false,
                        measurement: currentState.measurement,
                        target: currentState.target,
                        due: currentState.due_date,
                      },
                      achievement: {
                        tracking: currentState.tracking,
                        achievement: currentState.review_achievement,
                        yearachievement: currentState.achievement,
                        resultfile: null,
                        isFileResult: currentState.file,
                      },
                    },
                  )
                })
              })

              this.scoreCards = tempScoreCards
            } else {
              this.scoreCards = []
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          // this.isSubmitted = true
        })
    },
    checkIfNull(val) {
      if (val !== null && val !== undefined) {
        return val
      }
      return ''
    },
    saveKra() {
      const validateSAVELength = this.$refs.validateSAVE.length
      const isAllTrue = []
      // console.log('validate Kra shj', validateSAVELength)
      this.$refs.validateSAVE.forEach((item, index) => {
        this.$refs.validateSAVE[index].validate().then(success => {
          isAllTrue.push(success)
          if (index === validateSAVELength - 1) {
            const result = isAllTrue.every(e => e === true)
            if (result){
              if (this.scoreCards.length > 0) {
                const data = new FormData()
                const toSubmitPlanning = []
                // console.log('dalam scorecards', this.scoreCards)
                // eslint-disable-next-line no-shadow
                this.scoreCards.forEach((card, index) => {
                  toSubmitPlanning.push({
                    objective_id: card.id,
                    objective_name: card.description,
                    kra: [],
                  })

                  const allKra = card.KRA

                  allKra.forEach(itemKRA => {
                    const { target } = itemKRA
                    const currentState = itemKRA.target
                    toSubmitPlanning[index].kra.push(
                      {
                        kra_id: itemKRA.id,
                        kra_name: itemKRA.description,
                        type: "planning",
                        target: {
                          target_id: this.checkIfNull(currentState.id),
                          measurement: this.checkIfNull(currentState.measurement),
                          target: this.checkIfNull(currentState.target),
                          due_date: this.checkIfNull(currentState.due),
                          remarks: this.checkIfNull(itemKRA.remark),
                        },
                      },
                    )
                    if (target.attachmentKra !== undefined && target.attachmentKra !== null) {
                      data.append(`file[${itemKRA.id}]`, target.attachmentKra)
                    }
                  })
                })

                const dataToString = JSON.stringify(toSubmitPlanning)

                data.append('input', dataToString)

                // data.forEach(x => {
                //   console.log('append', x)
                // })

                // eslint-disable-next-line no-unused-vars
                const config = {
                  method: 'post',
                  url: `${this.$baseUrl}/kra/store_planning`,
                  data,
                }

                this.$axios(config)
                  .then(() => {
                    // console.log('response save changes', res.data)
                    this.whichPartNow()
                    setTimeout(() => {
                      this.$emit('close')
                      this.$emit('approve-performance')
                      this.$toast(
                        {
                          component: SAJToast,
                          props: {
                            title: `${this.$t('Successfully save changes')}!`,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                            titleColor: '#000',
                          },
                        },
                        {
                          position: "top-right",
                          type: 'info',
                        },
                      )
                      this.isSavingPlanning = false
                    }, 1000)
                  })
              }
            } else {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: result.message,
                    text: `${this.$t('Please fill all required fields')}!`,
                    icon: 'XIcon',
                    iconBg: '#e80202',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
              this.isSavingPlanning = false
              // alert(result.message)
            }
            // else {
            //   // eslint-disable-next-line no-alert
            //   alert('Please fill all required fields')
            // }
          }
        })
      })
    },
    async saveReview() {
      const validateSAVELength = this.$refs.validateSAVE.length
      this.$refs.validateSAVE.forEach((item, index) => {
        this.$refs.validateSAVE[index].validate().then(async success => {
          if (index === validateSAVELength - 1) {
            if (success){
              const allScoreCardPromises = Promise.all(this.scoreCards.map(card => {
                const allKra = card.KRA

                const allKraPromises = Promise.all(allKra.map(itemKRA => {
                  const { achievement } = itemKRA
                  const { target } = itemKRA

                  const data = new FormData()
                  data.append('objective_name', card.description)
                  data.append('kra_id[]', itemKRA.id)
                  if (card.id !== undefined && card.id !== null) {
                    data.append(`objective_id[${itemKRA.id}]`, card.id)
                  }
                  if (itemKRA.description !== undefined && itemKRA.description !== null) {
                    data.append(`kra_name[${itemKRA.id}]`, itemKRA.description)
                  }
                  if (achievement.achievement !== undefined && achievement.achievement !== null) {
                    data.append(`achievement[${itemKRA.id}]`, achievement.achievement)
                  }
                  if (achievement.achievement_to_date !== undefined && achievement.achievement_to_date !== null) {
                    data.append(`achievement_to_date[${itemKRA.id}]`, achievement.achievement_to_date)
                  }
                  if (achievement.tracking !== undefined && achievement.tracking !== null) {
                    data.append(`tracking[${itemKRA.id}]`, achievement.tracking)
                  }
                  if (itemKRA.remark !== undefined && itemKRA.remark !== null) {
                    data.append(`remarks[${itemKRA.id}]`, itemKRA.remark)
                  }
                  if (Number(itemKRA.self_score) !== undefined && Number(itemKRA.self_score) !== null) {
                    data.append(`rating[${itemKRA.id}]`, Number(itemKRA.self_score))
                  }
                  if (target.measurement !== undefined && target.measurement !== null) {
                    data.append(`measurement[${itemKRA.id}]`, target.measurement)
                  }
                  if (target.target !== undefined && target.target !== null) {
                    data.append(`target[${itemKRA.id}]`, target.target)
                  }
                  if (target.due !== undefined && target.due !== null) {
                    data.append(`due_date[${itemKRA.id}]`, target.due)
                  }
                  if (achievement.file instanceof File) {
                    data.append(`file[${itemKRA.id}]`, achievement.file)
                  }

                  // data.forEach(a => {
                  //   console.log("append review", a)
                  // })

                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/store_achievement`,
                    data,
                  }

                  return this.$axios(config)
                    .then(response => {
                      const result = response.data
                      if (result.success) {
                        // console.log('masuk kah review', response)
                        this.isSuccessReview = true
                        this.whichPartNow()
                        this.$emit('close')
                        this.$emit('approve-performance')
                      } else {
                        this.isSuccessReview = false
                        // console.log("masuk else luar")
                      }

                      return response
                    })
                    // .catch(error => {
                    //   const res = error.response
                    //   console.log("error kra")
                    //   if (res.status === 404) {
                    //     this.$toast(
                    //       {
                    //         component: SAJToast,
                    //         props: {
                    //           title: `${this.$t('Please fill Key Result Area fields')}!`,
                    //           icon: 'XIcon',
                    //           iconBg: '#e80202',
                    //           variant: 'success',
                    //           titleColor: '#000',
                    //         },
                    //       },
                    //       {
                    //         position: "top-right",
                    //         type: 'info',
                    //       },
                    //     )
                    //   }
                    // })
                  // here inside loop kra
                }))
                return allKraPromises
                // here inside loop scorecard
              }))
              allScoreCardPromises.then(() => {
                // console.log('selepas')
                if (this.isSuccessReview){
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t('Successfully save changes')}!`,
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                  this.isSavingReview = false
                } else {
                  // console.log('fggg')
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        // title: result.message,
                        title: 'Please submit planning first !',
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                  this.isSavingReview = false
                }
              }).catch(error => {
                const res = error.response
                // console.log("error kra")
                if (res.status === 404) {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t('Please fill Key Result Area fields')}!`,
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                }
              })
            } else {
              this.isSavingReview = false
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: 'Please fill required fields',
                    icon: 'XIcon',
                    iconBg: '#e80202',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }
          }
        })
      })
    },
    saveResult() {
      const validateSAVELength = this.$refs.validateSAVE.length
      this.$refs.validateSAVE.forEach((item, index) => {
        this.$refs.validateSAVE[index].validate().then(async success => {
          if (index === validateSAVELength - 1) {
            if (success){
              const allScoreCardPromisesResult = Promise.all(this.scoreCards.map(card => {
                const allKra = card.KRA

                const allKraPromisesResult = Promise.all(allKra.map(itemKRA => {
                  // allKra.forEach(itemKRA => {
                  const { achievement } = itemKRA
                  const { target } = itemKRA

                  const data = new FormData()
                  data.append('objective_name', card.description)
                  data.append('kra_id[]', itemKRA.id)
                  // if (achievement.achievement === null && achievement.achievement === undefined) {
                  //   data.append(`achievement[${itemKRA.id}]`, 'takde mid achievement')
                  // }
                  if (achievement.yearachievement !== null && achievement.yearachievement !== undefined) {
                    data.append(`achievement[${itemKRA.id}]`, achievement.yearachievement)
                  }
                  if (achievement.tracking !== null && achievement.tracking !== undefined) {
                    data.append(`tracking[${itemKRA.id}]`, achievement.tracking)
                  }
                  if (itemKRA.remark !== null && itemKRA.remark !== undefined) {
                    data.append(`remarks[${itemKRA.id}]`, itemKRA.remark)
                  }
                  if (Number(itemKRA.self_score) !== null && Number(itemKRA.self_score) !== undefined) {
                    data.append(`rating[${itemKRA.id}]`, Number(itemKRA.self_score))
                  }
                  if (achievement.resultfile instanceof File) {
                    data.append(`file[${itemKRA.id}]`, achievement.resultfile)
                  }
                  if (itemKRA.description !== undefined && itemKRA.description !== null) {
                    data.append(`kra_name[${itemKRA.id}]`, itemKRA.description)
                  }
                  if (card.id !== undefined && card.id !== null) {
                    data.append(`objective_id[${itemKRA.id}]`, card.id)
                  }
                  if (target.measurement !== undefined && target.measurement !== null) {
                    data.append(`measurement[${itemKRA.id}]`, target.measurement)
                  }
                  if (target.target !== undefined && target.target !== null) {
                    data.append(`target[${itemKRA.id}]`, target.target)
                  }
                  if (target.due !== undefined && target.due !== null) {
                    data.append(`due_date[${itemKRA.id}]`, target.due)
                  }

                  // data.forEach(a => {
                  //   console.log("append review", a)
                  // })

                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/store_result`,
                    data,
                  }

                  return this.$axios(config)
                    .then(response => {
                      const result = response.data
                      if (result.success) {
                        // console.log('masuk result')
                        this.isSuccessResult = true
                        this.whichPartNow()
                        // setTimeout(() => {
                        this.$emit('close')
                        this.$emit('approve-performance')
                        // }, 1000)
                      } else {
                        this.isSuccessResult = false
                      }
                      return response
                    })
                    .catch(error => {
                      const res = error.response
                      // console.log("error kra")
                      if (res.status === 404) {
                        this.$toast(
                          {
                            component: SAJToast,
                            props: {
                              title: `${this.$t('Please fill Key Result Area fields')}!`,
                              icon: 'XIcon',
                              iconBg: '#e80202',
                              variant: 'success',
                              titleColor: '#000',
                            },
                          },
                          {
                            position: "top-right",
                            type: 'info',
                          },
                        )
                      }
                    })
                }))
                return allKraPromisesResult
              }))
              allScoreCardPromisesResult.then(() => {
                // console.log('save')
                if (this.isSuccessResult){
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t('Successfully save changes')}!`,
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                  this.isSavingResult = false
                } else {
                  // console.log('Not Save')
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        // title: result.message,
                        title: 'Please submit review first !',
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                }
              })
              // this.scoreCards.forEach(card => {
            } else {
              this.isSavingResult = false
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: 'Please fill required fields',
                    icon: 'XIcon',
                    iconBg: '#e80202',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }
          }
        })
      })
      // })
    },
    submitResult() {
      this.$swal({
        title: `${this.$i18n.t('Are you sure to finalize the Year End Result?')}`,
        text: `${this.$i18n.t("You won't be able to revert this!")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${this.$i18n.t('Yes, Sure!')}`,
        cancelButtonText: `${this.$i18n.t('Cancel!')}`,
      }).then(result => {
        if (result.isConfirmed) {
          const validateKRALength = this.$refs.validateKRA.length
          this.$refs.validateKRA.forEach((item, index) => {
            this.$refs.validateKRA[index].validate().then(success => {
              if (index === validateKRALength - 1) {
                if (success){
                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/submit_result`,
                  }

                  this.$axios(config)
                    .then(response => {
                      const res = response.data
                      if (res.success) {
                        this.whichPartNow()
                        this.$swal(
                          `${this.$t('Successfully Saved!')}`,
                          ``,
                          'success',
                        )
                      } else {
                        // alert(res.message)
                        this.$swal(
                          res.message,
                          ``,
                          'error',
                        )
                      }
                    })
                    .catch(() => {
                      // console.log(error)
                    })
                } else {
                  // eslint-disable-next-line no-alert
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: result.message,
                        text: `${this.$t('Please fill all required fields')}!`,
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                }
              }
            })
          })
          // end
        }
      })
    },
    submitKra() {
      this.$swal({
        title: `${this.$i18n.t('Are you sure to proceed to Performance Review?')}`,
        text: `${this.$i18n.t("You won't be able to revert this!")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${this.$i18n.t('Yes, Sure!')}`,
        cancelButtonText: `${this.$i18n.t('Cancel!')}`,
      }).then(result => {
        if (result.isConfirmed) {
          const validateKRALength = this.$refs.validateKRA.length
          this.$refs.validateKRA.forEach((item, index) => {
            this.$refs.validateKRA[index].validate().then(success => {
              if (index === validateKRALength - 1) {
                if (success){
                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/submit_planning`,
                  }

                  this.$axios(config)
                    .then(response => {
                      // console.log('res submit', response)
                      const res = response.data
                      if (res.success) {
                        this.whichPartNow()
                        this.$swal(
                          `${this.$t('Successfully Saved!')}`,
                          ``,
                          'success',
                        )
                      } else {
                        this.$swal(
                          res.message,
                          ``,
                          'error',
                        )
                      }
                    })
                    .catch(() => {
                      // console.log(error)
                    })
                } else {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: result.message,
                        text: `${this.$t('Please fill all required fields')}!`,
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                }
              }
            })
          })

          // this.$swal(
          //   `${this.$t('Successfully Saved!')}`,
          //   ``,
          //   'success',
          // )
        }
      })
    },
    submitReview() {
      this.$swal({
        title: `${this.$i18n.t('Are you sure to proceed to Year End Result?')}`,
        text: `${this.$i18n.t("You won't be able to revert this!")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${this.$i18n.t('Yes, Sure!')}`,
        cancelButtonText: `${this.$i18n.t('Cancel!')}`,
      }).then(result => {
        if (result.isConfirmed) {
          const validateKRALength = this.$refs.validateKRA.length
          this.$refs.validateKRA.forEach((item, index) => {
            this.$refs.validateKRA[index].validate().then(success => {
              if (index === validateKRALength - 1) {
                if (success){
                  const config = {
                    method: 'post',
                    url: `${this.$baseUrl}/kra/submit_review`,
                  }

                  this.$axios(config)
                    .then(response => {
                      const res = response.data
                      if (res.success) {
                        this.whichPartNow()
                        this.$swal(
                          `${this.$t('Successfully Saved!')}`,
                          ``,
                          'success',
                        )
                      } else {
                        this.$swal(
                          res.message,
                          ``,
                          'error',
                        )
                      }
                    })
                    .catch(() => {
                      // console.log(error)
                    })
                } else {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: result.message,
                        text: `${this.$t('Please fill all required fields')}!`,
                        icon: 'XIcon',
                        iconBg: '#e80202',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                }
              }
            })
          })
        }
      })
    },
    getDetails() {
      this.$axios.post(`${this.$baseUrl}/myperformances/getUserPerformances`).then(response => {
        if (response.data.data.length !== 0){
          // for (let i = 0; i < response.data.data.length; i += 1) {
          //   // this.status = response.data.data[i].status
          // }

          const result = response.data.data
          const statusArray = result.filter(x => x.performance_id === this.id)

          // eslint-disable-next-line prefer-destructuring
          this.indicator = statusArray[0]
        }
      })
    },

  },
}
</script>
