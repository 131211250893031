<template>
  <div
    class="pb-1"
    style=""
  >

    <b-card
      class="m-0 mb-2"
      style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              text-align: center;
              "
    >
      <div
        v-if="isSelf"
        class="pl-1 pt-1 d-flex align-items-center"
      >
        <b-button
          variant="primary"
          @click="toPart1(id)"
        >
          {{ $t('See Details') }}
        </b-button>
      </div>
      <div
        v-else-if=" isSupervisor === 1 || isAppraiserSv === 1"
        class="pl-1 pt-1 d-flex align-items-center"
      >
        <b-button
          variant="primary"
          @click="toPart1TeamPerformance(teamId, performanceId)"
        >
          {{ $t('See Details') }}
        </b-button>
      </div>

      <div
        class="mt-2 mx-1"
      >
        <b-table
          show-empty
          :items="summaryList"
          :fields="fields"
          bordered
          responsive

          class="customTable"
        >
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-subtitle"
            >{{ $t(data.label) }}</span>
          </template>

          <template #cell(objective_name)="data">
            <span class="saj-text">{{ $t(data.item.objective_name) }}</span>

            <!-- <router-link
              :to="{name: 'my-performance-part1'}"
            >
              <b-button
                variant="primary"
              >
                {{ $t('See Details') }}
              </b-button>
            </router-link> -->
          </template>

          <template #cell(kra_name)="data">
            <div
              v-for="item in data.item.key_result_area"
              :key="item.kra_id"
              style="height: 120px; overflow: auto;"
              class="d-flex align-items-center justify-content-center saj-text"
              :style="data.item.key_result_area.length === 1? {'border': 'none'} : ''"
            >
              {{ $t(item.kra_name) }}
            </div>
          </template>

          <template #cell(measurement)="data">
            <div
              v-for="item in data.item.key_result_area"
              :key="item.id"
              style="height: 120px; overflow: auto;"
              class="d-flex align-items-center justify-content-center saj-text"
              :style="data.item.key_result_area.length === 1? {'border': 'none'} : ''"
            >
              {{ item.measurement !== null ? item.measurement : '-' }}
            </div>
          </template>

          <template #cell(target)="data">
            <div
              v-for="item in data.item.key_result_area"
              :key="item.id"
              style="height: 120px; overflow: auto;"
              class="d-flex align-items-center justify-content-center saj-text"
              :style="data.item.key_result_area.length === 1? {'border': 'none'} : ''"
            >
              {{ $t(item.target !== null ? item.target : '-') }}
            </div>
          </template>

          <template #cell(due_date)="data">
            <div
              v-for="item in data.item.key_result_area"
              :key="item.id"
              style="height: 120px; overflow: auto;"
              class="d-flex align-items-center justify-content-center saj-text"
              :style="data.item.key_result_area.length === 1? {'border': 'none'} : ''"
            >
              {{ $t(DateChanged(item.due_date !== null ? item.due_date: '-' )) }}
            </div>
          </template>

          <template #cell(remarks)="data">
            <div
              v-for="item in data.item.key_result_area"
              :key="item.id"
              style="height: 120px; overflow: auto;"
              class="d-flex align-items-center justify-content-center saj-text"
              :style="data.item.key_result_area.length === 1? {'border': 'none'} : ''"
            >
              {{ $t(item.remarks !== null ? item.remarks : '-') }}
            </div>
          </template>
        </b-table>
      </div>

    </b-card>

  </div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import moment from "moment"
// import ApproveBulkEmployee from "@/component/hod/ApproveBulkEmployee.vue"
import {
  BCard,
  // BCol,
  // BCardHeader,
  // BCardBody,
  BTable,
  // BImg,
  BButton,
  VBTooltip,
  VBPopover,
} from "bootstrap-vue"
// import { forEach } from "postcss-rtl/lib/affected-props"
// import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // BCol,
    // BCardHeader,
    // BCardBody,
    BButton,
    // BContainer,
    // BFormInput,
    // BFormSelect,
    BTable,
    // BOverlay,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    performanceId: {
      type: null,
      default: null,
    },
    teamId: {
      type: null,
      default: null,
    },
    isSelf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    //   showEdit: null,
      show: true,
      id: this.$route.params.id,
      summaryList: [],
      isSupervisor: null,
      isAppraiserSv: null,

      fields: [
        { key: 'objective_name', label: 'Objective', thClass: 'text-center' },
        {
          key: 'kra_name', label: 'KRA', thClass: 'text-center',
        },
        {
          key: 'measurement', label: 'Measurement', thClass: 'text-center',
        },
        { key: 'target', label: 'Target', thClass: 'text-center' },
        { key: 'due_date', label: 'Deadline', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
      ],
      division_id: this.$route.params.division_id,
      section_id: this.$route.params.section_id,
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
  },
  mounted() {
    this.getSummaryKra()
    this.checkSupervisorStatus()
    // console.log("perf id diri sendiri", this.performanceId)
    // console.log('anak perf id', this.id)
    // console.log(this.division_id)
  },
  methods: {
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performanceId || this.id}&user_id=${this.userDetails.user_id}`).then(response => {
        // console.log('res', response)
        this.isSupervisor = response.data.data.status.is_supervisor
        this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    toPart1(id) {
      // to kra main
      this.$router.push({ name: 'my-performance-part1', params: { id } })
    },
    toPart1TeamPerformance(teamId, performanceId){
      this.$router.push({ name: 'team-performance-individual-part1', params: { teamId, performanceId } })
      // console.log('id team', performanceId)
    },

    getSummaryKra() {
      this.$axios.get(`${this.$baseUrl}/kra/summary_get_planning?cycle_id=${this.performanceId || this.id}`).then(response => {
        this.summaryList = response.data.data.objective
      })
    },

  },

}
</script>
<style>
  .customTable>tbody>tr>td:nth-child(n+2) {
    padding: 0;
  }

  .customTable>tbody>tr>td:nth-child(n+2)>div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customTable>tbody>tr>td:nth-child(n+2)>div:nth-child(n+1) {
    border-bottom: 1px solid #ebe9f1;
  }

</style>
<!--     border-bottom: 1px solid #ebe9f1; -->
